import { User } from '@microsoft/microsoft-graph-types';
import { graphLoginRequest } from "../Config";
import { IPublicClientApplication } from "@azure/msal-browser";

var graph = require('@microsoft/microsoft-graph-client');

export default class GraphService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getUserDetails(): Promise<User> {
        const client = await this.getAuthenticatedClient();

        const user = await client
            .api('/me')
            .select('displayName,mail,userPrincipalName,id,onPremisesUserPrincipalName')
            .get();

        sessionStorage.setItem("onPremisesUserPrincipalName", user.onPremisesUserPrincipalName ?? "");

        return user;
    }

    getAuthenticatedClient = async () => {
        const accessToken = await this.acquireAccessToken();
        const client = graph.Client.init({
            authProvider: (done: any) => {
                done(null, accessToken);
            }
        });

        return client;
    }

    acquireAccessToken = async () => {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...graphLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}
