import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import EmployeeService from "../../Services/EmployeeService";
import "../GlobalStyles.css";
import EmployeeFamily from "../../Models/EmployeeFamily";
import { toast } from "react-toastify";
import FuelCardAllocation from "../../Models/FuelCardAllocation";
import FuelCardService from "../../Services/FuelCardService";
import UserFuelCardForm from "./UserFuelCardForm";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import UserHeaderComponent from "../UserHeaderComponent";
import { useMsal } from "@azure/msal-react";
import GraphService from '../../Services/GraphService';

export default function UserFuelCardPage(props: any) {
    const { instance } = useMsal();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const fuelCardService = useMemo(() => new FuelCardService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const history = useHistory();
    const [fuelCard, setFuelCard] = useState<FuelCardAllocation>();

    useEffect(() => {
        getEmployee();
    }, [])

    async function getEmployee() {
        let user = await graphService.getUserDetails();
        let userName = user?.userPrincipalName as string;
        if (userName === null) {
            toast.error(`Kan geen gebruiker vinden.`);
            goBackToOverViewApp();
        }

        employeeService.getEmployeeFamilyByUserName(userName).then((data: EmployeeFamily) => {
            if (!data) {
                toast.error(`Kan geen gebruiker met de gebruikersnaam ${userName} vinden.`);
                goBackToOverViewApp();
            }
            return data;
        }).then((data: EmployeeFamily) => {
            fuelCardService.getFuelCardByEmployeeContactId(data.contactId).then((fuelCard: FuelCardAllocation) => {
                setFuelCard(fuelCard);
            });
        });
    }

    function goBackToOverViewApp(): void {
        history.push("/overview");
    }

    function getLoadSpinner() {
        return (
            <MoonLoader
                color="black"
                loading={!fuelCard}
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 20px);
                    top: 125px`
                }
                size={25} />
        );
    }

    function getPersonalDataDiv() {
        return (
            <div className="p-0 m-0">
                {fuelCard ?
                    <UserFuelCardForm fuelCardAllocation={fuelCard} props={props} />
                    : ""}
            </div>
        );
    }

    return (
        <>
            {getLoadSpinner()}
            <UserHeaderComponent headerText={"Gegevens tankkaart"} />

            {fuelCard &&
                <>
                    {getPersonalDataDiv()}
                </>
            }
        </>
    );
}