import Brand from "./Brand";
import MileAge from "./MileAge";

export default interface CompanyCar {
    // Algemeen
    companyCarId: string;
    ownerId: string;
    licensePlate: string;
    chassisNumber: string;
    firstRegistration: Date;
    fuelType: FuelType;
    co2: number;
    brand: Brand;
    type: string;

    // Verzekering
    insuranceNumber: string;
    fleetNumber: string;
    franchise: number;

    mileAge: MileAge;
}

export enum FuelType {
    Benzine = 220950000,
    Diesel = 220950001,
    Hybride = 220950002,
    Elektrisch = 220950003
}