import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";
import DocumentFile from "../Models/DocumentFile";
import DocumentFileResponse from "../Models/Responses/DocumentFileResponse";

export default class DocumentService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getPersonalDocumentsByName(): Promise<DocumentFile[]> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetPersonalDocumentsByUserName`;
        let documentFiles: DocumentFile[] = [];
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'OnPremisesUserPrincipalName': sessionStorage.getItem('onPremisesUserPrincipalName') ?? ""
            })
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    data.forEach((responseObject: any) => {
                        const documentFile: DocumentFile = mapJsonObjectToDocumentFile(responseObject);
                        documentFiles.push(documentFile);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });
        return documentFiles;
    }

    public async getUploadedDocumentsByName(): Promise<DocumentFile[]> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetUploadedDocumentsByUserNameFunction`;
        let documentFiles: DocumentFile[] = [];
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'OnPremisesUserPrincipalName': sessionStorage.getItem('onPremisesUserPrincipalName') ?? ""
            })
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    data.forEach((responseObject: any) => {
                        const documentFile: DocumentFile = mapJsonObjectToDocumentFile(responseObject);
                        documentFiles.push(documentFile);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return documentFiles;
    }

    public async getDocumentBase64StringByName(documentFileResponse: DocumentFileResponse): Promise<string> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetDocumentBase64StringByFileNameFunction`;
        let byteArray: string = null as any;
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'OnPremisesUserPrincipalName': sessionStorage.getItem('onPremisesUserPrincipalName') ?? ""
            }),
            body: JSON.stringify(documentFileResponse)
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    byteArray = data;
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
            throw new Error();
        });

        return byteArray;
    }

    public async uploadDocument(documentFile: DocumentFile): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/UploadDocument`;
        let created = false;
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'OnPremisesUserPrincipalName': sessionStorage.getItem('onPremisesUserPrincipalName') ?? ""
            }),
            body: JSON.stringify(documentFile)
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }
            created = true;
        }).catch((error: Error) => {
            console.log(error.message)
        })

        return created;
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}

function mapJsonObjectToDocumentFile(jsonObject: any): DocumentFile {
    return {
        fileName: jsonObject.FileName,
        fileNameWithPrefix: jsonObject.FileNameWithPrefix,
        fileExtension: jsonObject.FileExtension,
        isInUploadFolder: jsonObject.IsInUploadFolder,
        createdDate: jsonObject.CreatedDate,
        typeDocument: jsonObject.TypeDocument,
        typeDocumentName: jsonObject.TypeDocumentName,
        typeAbsenceCertificate: jsonObject.TypeAbsenceCertificate,
        contentType: jsonObject.ContentType,
        fileBytes: ""
    }
}
