import { Row } from "reactstrap";
import "./GlobalStyles.css";
import { getEmployeeFunction, getEmployeeUserName } from "../Helpers/StorageHelper";

interface Props {
    headerText: string;
}

export default function UserHeaderComponent(props: Props) {    
    const employeeFunction = getEmployeeFunction() as string;
    const userName = getEmployeeUserName() as string;
    
    return (
        <div className="position-relative background-color-blue">
            <Row className="background-color-blue pr-3 pl-3 pt-2">
                <div className="mx-auto">
                    <h3>{userName ?? ""}</h3>
                </div>
            </Row>
            <Row className="background-color-blue pr-3 pl-3 mt-0">
                <div className="mx-auto">
                    <h4>{employeeFunction ?? ""}</h4>
                </div>
            </Row>
            <Row className="background-color-blue pr-3 pl-3 mt-0">
                <h5>{props.headerText}</h5>
            </Row>
        </div>
    );
}