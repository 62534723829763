import "../GlobalStyles.css";
import ClbLogo from '../../Assets/ClbGroupLogo.png'
import { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, ButtonToolbar, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import WeeklyNewsItem, { TargetGroup, WeeklyNewsItemType, getTargetGroupString } from "../../Models/WeeklyNewsItem";
import WeeklyNewsItemService from "../../Services/WeeklyNewsItemService";
import ReminderService from "../../Services/ReminderService";
import Reminder from "../../Models/Reminder";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { useHistory } from "react-router";
import { useMsal } from "@azure/msal-react";
import GraphService from '../../Services/GraphService';
import { useForm } from "react-hook-form";
import EmployeeService from "../../Services/EmployeeService";
import Employee from "../../Models/Employee";
import { getEmployeeUserName, setEmployeeFunction, setEmployeeUserName } from "../../Helpers/StorageHelper";
import HandshakeIcon from '@mui/icons-material/Handshake';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import StarIcon from '@mui/icons-material/Star';
import CakeIcon from '@mui/icons-material/Cake';
import InfoIcon from '@mui/icons-material/Info';
import { removePushNotificationUserId, setPushNotificationUserId } from "../../Helpers/OneSignalNotificationsHelper";
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from "@mui/material";
import useWindowHeight from "../../Hooks/useWindowHeight";

export default function OverviewAppPage() {
    const { windowHeight } = useWindowHeight();
    const { instance } = useMsal();
    const weeklyNewsItemService = useMemo(() => new WeeklyNewsItemService(instance), [instance]);
    const reminderService = useMemo(() => new ReminderService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const history = useHistory();
    const [showPopup, setShowPopup] = useState(false);
    const [weeklyNewsItems, setWeeklyNewsItems] = useState<WeeklyNewsItem[]>([]);
    const [reminders, setReminders] = useState<Reminder[]>([]);
    const [isListExpanded, setIsListExpanded] = useState(false);
    const [areWeeklyNewsLoaded, setAreWeeklyNewsLoaded] = useState(false);
    const [areRemindersLoaded, setAreRemindersLoaded] = useState(false);
    const [displayName, setDisplayName] = useState<string>(getEmployeeUserName() ?? "");
    const amountOfItemsShown = 4;

    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: {
            subject: "",
            message: "",
            type: WeeklyNewsItemType.Diverse,
            targetGroup: TargetGroup.AllEmployees
        }
    });

    const [showNewWeeklyNewsPopup, setShowNewWeeklyNewsPopup] = useState(false);
    const [showWeeklyNewsPopup, setShowWeeklyNewsPopup] = useState(false);
    const [currentWeeklyNewsItem, setCurrentWeeklyNewsItem] = useState<WeeklyNewsItem>();
    const [editableWeeklyNewsItem, setEditableWeeklyNewsItem] = useState<WeeklyNewsItem>();

    useEffect(() => {
        getUser();
        refreshWeeklyNewsItemsAndReminders(true);
    }, []);

    async function getUser() {
        try {
            let user = await graphService.getUserDetails();
            setDisplayName(user?.displayName ?? "");
            setEmployeeUserName(user?.displayName ?? "");
        }
        catch (error: any) {
            setDisplayName("Foutieve gebruiker. Gelieve af te melden.");
            setEmployeeUserName("Foutieve gebruiker. Gelieve af te melden.");
        }
    }

    async function refreshWeeklyNewsItemsAndReminders(init: boolean): Promise<void> {
        weeklyNewsItemService.getAllWeeklyNewsItems().then((items) => {
            setWeeklyNewsItems(items);
            setAreWeeklyNewsLoaded(true);
        })

        reminderService.getAllReminders().then((items) => {
            setReminders(items);
            setAreRemindersLoaded(true);
        })

        employeeService.getEmployeeByUserName().then((data: Employee) => {
            if (data) {
                setEmployeeFunction(data.function?.name ?? "");

                if (init)
                    setPushNotificationUserId(data.contactId);
            }
        });
    }

    function toggleWeeklyNewsList(): void {
        setIsListExpanded(!isListExpanded)
    }

    function getWeeklyNewsItems(): WeeklyNewsItem[] {
        if (isListExpanded) {
            return weeklyNewsItems;
        } else {
            return weeklyNewsItems.slice(0, amountOfItemsShown);
        }
    }

    function logout() {
        setShowPopup(false);
        removePushNotificationUserId();
        instance.logoutRedirect();
    }

    function getNoDataDiv() {
        return (
            <Row className="pr-3 pl-3 mt-0 mb-3">
                <Label><b>Geen data beschikbaar</b></Label>
            </Row>
        );
    }

    function getLoadSpinner() {
        return (
            <MoonLoader
                color="black"
                loading={!areWeeklyNewsLoaded || !areRemindersLoaded}
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    margin-top: 15px;`
                }
                size={25} />
        );
    }

    function getIconByType(type: number) {
        const colorStyle = { color: "#2F5FA1" };

        if (type === WeeklyNewsItemType.Birthday) {
            return <CakeIcon style={colorStyle} />
        } else if (type === WeeklyNewsItemType.Seniority) {
            return <ThumbUpIcon style={colorStyle} />
        } else if (type === WeeklyNewsItemType.Birth) {
            return <ChildCareIcon style={colorStyle} />
        } else if (type === WeeklyNewsItemType.Event) {
            return <StarIcon style={colorStyle} />
        } else if (type === WeeklyNewsItemType.Diverse) {
            return <HandshakeIcon style={colorStyle} />
        }
    }

    function getPopup() {
        return (
            <Modal isOpen={showPopup} toggle={() => setShowPopup(false)} centered>
                <ModalHeader className="d-flex justify-content-center">Bent u zeker dat u wilt afmelden?</ModalHeader>
                <ModalBody className="p-2">
                    <ButtonToolbar className="navbar p-0">
                        <Button color="primary" style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%" }} onClick={() => setShowPopup(false)}>Annuleer</Button>
                        <Button color="primary" style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%" }} onClick={logout}>Ja</Button>
                    </ButtonToolbar>
                </ModalBody>
            </Modal>
        );
    }

    function getWeeklyNewsTypeString(weeklyNewsItem: WeeklyNewsItemType): string {
        switch (weeklyNewsItem) {
            case WeeklyNewsItemType.Birth:
                return "Geboorte";
            case WeeklyNewsItemType.Birthday:
                return "Verjaardag";
            case WeeklyNewsItemType.Event:
                return "Evenement";
            case WeeklyNewsItemType.Seniority:
                return "Anciënniteit";
            case WeeklyNewsItemType.Diverse:
                return "Diverse";
            default:
                return "";
        }
    }

    function openNewWeeklyNewsPopup() {
        setEditableWeeklyNewsItem(undefined);
        reset({
            subject: "",
            message: "",
            type: WeeklyNewsItemType.Diverse,
            targetGroup: TargetGroup.AllEmployees
        });
        setShowNewWeeklyNewsPopup(true);
    }

    const upsertWeeklyNews = async () => {
        handleSubmit(async (data) => {

            if (editableWeeklyNewsItem) {

                await weeklyNewsItemService.updateWeeklyNews({
                    id: currentWeeklyNewsItem?.id,
                    subject: data.subject,
                    message: data.message,
                    type: data.type,
                    targetGroup: data.targetGroup
                });

                refreshWeeklyNewsItemsAndReminders(false);
                setEditableWeeklyNewsItem(undefined);
            }
            else {
                const today = new Date();
                const today1Month = new Date();
                today1Month.setMonth(today1Month.getMonth() + 1);
                await weeklyNewsItemService.updateWeeklyNews({
                    subject: data.subject,
                    message: data.message,
                    type: data.type,
                    createdOn: today,
                    fromDate: today,
                    untilDate: today1Month,
                    targetGroup: data.targetGroup
                });
                refreshWeeklyNewsItemsAndReminders(false);
            }
            reset();
            setShowNewWeeklyNewsPopup(false);
        })()
    }

    function isFieldEmpty(data: any) {
        return data === null || data === undefined || data?.toString().trim() === "";
    }

    function getNewWeeklyNewsPopup() {
        return (
            <Modal isOpen={showNewWeeklyNewsPopup} toggle={() => setShowNewWeeklyNewsPopup(false)} centered>
                <ModalHeader className="d-flex justify-content-center">{editableWeeklyNewsItem?.subject ?? "Nieuw bericht"}</ModalHeader>
                <ModalBody className="p-2">
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Onderwerp:<span style={{ color: "red" }}>*</span></Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("subject", { required: true, validate: (value) => !isFieldEmpty(value) })} className="inputs-fixed-height form-control" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {errors.subject && (
                                <Label style={{ color: "red" }} className="ml-2">Veld moet ingevuld zijn</Label>
                            )}
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Type:<span style={{ color: "red" }}>*</span></Label>
                        </Col>
                        <Col xs="12">
                            <select {...register("type", { required: true, validate: (value) => !isFieldEmpty(value) })} className="inputs-fixed-height form-control form-control-md p-0 pl-2">
                                <option value={WeeklyNewsItemType.Birthday}>{getWeeklyNewsTypeString(WeeklyNewsItemType.Birthday)}</option>
                                <option value={WeeklyNewsItemType.Diverse}>{getWeeklyNewsTypeString(WeeklyNewsItemType.Diverse)}</option>
                                <option value={WeeklyNewsItemType.Event}>{getWeeklyNewsTypeString(WeeklyNewsItemType.Event)}</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {errors.type && (
                                <Label style={{ color: "red" }}>Veld moet ingevuld zijn</Label>
                            )}
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Doelgroep:<span style={{ color: "red" }}>*</span></Label>
                        </Col>
                        <Col xs="12">
                            <select {...register("targetGroup", { required: true, validate: (value) => !isFieldEmpty(value) })} className="inputs-fixed-height form-control form-control-md p-0 pl-2">
                                <option value={TargetGroup.AllEmployees}>{getTargetGroupString(TargetGroup.AllEmployees)}</option>
                                <option value={TargetGroup.MyCompany}>{getTargetGroupString(TargetGroup.MyCompany)}</option>
                                <option value={TargetGroup.MyDivision}>{getTargetGroupString(TargetGroup.MyDivision)}</option>
                                <option value={TargetGroup.MyDepartment}>{getTargetGroupString(TargetGroup.MyDepartment)}</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Bericht:</Label>
                        </Col>
                        <Col xs="12">
                            <textarea rows={10} {...register("message")} className="form-control">
                            </textarea>
                        </Col>
                    </Row>
                    <ButtonToolbar className="navbar p-0">
                        <Button color="primary" style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%" }} onClick={() => setShowNewWeeklyNewsPopup(false)}>Annuleer</Button>
                        <Button color="primary" style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%" }} onClick={(upsertWeeklyNews)}>Opslaan</Button>
                    </ButtonToolbar>
                </ModalBody>
            </Modal>
        );
    }

    function openWeeklyNewsPopup(weeklyNewsItem: WeeklyNewsItem) {
        setCurrentWeeklyNewsItem(weeklyNewsItem);
        setShowWeeklyNewsPopup(true);
    }

    function editWeeklyNewsPopup(weeklyNewsItem: WeeklyNewsItem) {
        setEditableWeeklyNewsItem(weeklyNewsItem);
        reset({
            subject: weeklyNewsItem.subject,
            message: weeklyNewsItem.message,
            type: weeklyNewsItem.type,
            targetGroup: weeklyNewsItem.targetGroup
        });
        setShowWeeklyNewsPopup(false);
        setShowNewWeeklyNewsPopup(true);
    }

    function getWeeklyNewsPopup() {
        return (
            <Modal isOpen={showWeeklyNewsPopup} toggle={() => setShowWeeklyNewsPopup(false)} centered>
                <ModalHeader className="d-flex justify-content-center">{currentWeeklyNewsItem?.subject}</ModalHeader>
                <ModalBody className="p-2">
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12">
                            <div style={{ whiteSpace: "pre-wrap" }}>
                                {currentWeeklyNewsItem?.message}
                            </div>
                        </Col>
                    </Row>
                    <hr className="p-0 m-0" />
                    <Row className="pl-2 pr-2 mb-0 d-flex align-items-right">
                        <Col xs="12">
                            <p className="text-right font-italic"><small>Geplaatst door {currentWeeklyNewsItem?.postedByEmployeeName}</small></p>
                        </Col>
                    </Row>
                    <ButtonToolbar className="navbar p-0">
                        {(currentWeeklyNewsItem?.editable === true) ?
                            <>
                                <Button color="primary" style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%", margin: "auto" }} onClick={() => editWeeklyNewsPopup(currentWeeklyNewsItem)}>Wijzigen</Button>
                                <Button color="primary" style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%", margin: "auto" }} onClick={() => setShowWeeklyNewsPopup(false)}>Sluiten</Button>
                            </>
                            :
                            <Button color="primary" style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "100%", margin: "auto" }} onClick={() => setShowWeeklyNewsPopup(false)}>Sluiten</Button>
                        }
                    </ButtonToolbar>
                </ModalBody>
            </Modal>
        );
    }

    function getHeader() {
        return (
            <div className="background-color-blue">
                <Row className="position-absolute p-2">
                    <i className="fas fa-sign-out-alt fa-2x" style={{ color: "white" }} onClick={() => setShowPopup(true)}></i>
                </Row>
                <span className="position-absolute" style={{ right: 0, top: 0 }}>
                    <IconButton onClick={() => history.push("/Settings")}>
                        <SettingsIcon className="color-white" fontSize="large" />
                    </IconButton>
                </span>
                <Row className="background-color-blue pr-3 pl-3 pt-3">
                    <img src={ClbLogo} alt="CLB Group" style={{ width: "10em" }} className="mx-auto mb-3" />
                </Row>
                <Row className="background-color-blue pr-3 pl-3 mt-0">
                    <h5>Medewerker: {displayName}</h5>
                </Row>
            </div>
        );
    }

    function getContent() {
        return (
            <>
                {getNewWeeklyNewsPopup()}
                {getWeeklyNewsPopup()}
                {getPopup()}
                {getLoadSpinner()}

                {areRemindersLoaded && areWeeklyNewsLoaded &&
                    <div className="p-0 pt-3 m-0" style={{ height: `calc(${windowHeight}px - 205px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}>
                        <Row className="d-flex align-items-center mb-2">
                            <Col>
                                <h5 className="mb-0"><b>Weeknieuws</b></h5>
                            </Col>
                        </Row>
                        <div className="pl-3">
                            {weeklyNewsItems.length ?
                                <>
                                    {getWeeklyNewsItems().map((weeklyNews, index) => {
                                        const hasWeeklyNewsInfo = weeklyNews.message !== null && weeklyNews.message?.trim() !== "";

                                        return (
                                            <Row key={index} className="mb-1"
                                                onClick={() => hasWeeklyNewsInfo && openWeeklyNewsPopup(weeklyNews)}>
                                                <Col xs="9 mb-2 d-flex align-items-center">
                                                    <b><Label className="m-0">{weeklyNews.subject}</Label></b>
                                                </Col>
                                                <Col xs="3" style={{ textAlign: "end" }}>
                                                    {hasWeeklyNewsInfo &&
                                                        <InfoIcon color="disabled" />
                                                    }
                                                    {getIconByType(weeklyNews.type)}
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                    {weeklyNewsItems.length > amountOfItemsShown &&
                                        <Row onClick={toggleWeeklyNewsList} style={{ color: "#007bff" }} className="d-flex justify-content-center">
                                            {!isListExpanded ?
                                                <i className="fas fa-caret-down fa-2x"></i> :
                                                <i className="fas fa-caret-up fa-2x"></i>
                                            }
                                        </Row>
                                    }
                                </>
                                : getNoDataDiv()}

                        </div>
                        <hr color="#007bff"></hr>
                        <h5><b>Reminder</b></h5>
                        <div className="pl-3">
                            {reminders.length ?
                                <>
                                    {reminders.map((reminder, index) => {
                                        return (
                                            <Row key={index}>
                                                <Col>
                                                    <b><Label>{reminder.subject}</Label></b>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </>
                                : getNoDataDiv()}
                        </div>
                    </div>
                }
            </>
        );
    }

    function getFooter() {
        return (
            <ButtonGroup className="fixed-bottom navbar p-0">
                <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/overview-user")}>
                    <i className="fas fa-user fa-2x"></i>
                </Button>
                <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("overview-document")}>
                    <i className="fas fa-file fa-2x"></i>
                </Button>
                <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={openNewWeeklyNewsPopup}>
                    <i className="fas fa-comment fa-2x"></i>
                </Button>
            </ButtonGroup>
        );
    }

    return (
        <>
            {getHeader()}
            {getContent()}
            {getFooter()}
        </>
    );
}