import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";
import Driver from "../Models/Driver";
import MileAgeResponse from "../Models/Responses/MileAgeResponse";

export default class DriverService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getDriverByEmployeeContactId(employeeContactId: string): Promise<Driver> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetDriverByContactId`;
        let companyCar: Driver = null as any;
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,

            }),
            body: JSON.stringify(employeeContactId)
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    companyCar = mapJsonObjectToCompanyCar(data);
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return companyCar;
    }

    public async addMileAge(mileAge: MileAgeResponse)
    {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/AddMileAge`;
        const accessToken = await this.acquireAccessToken();
        let isAdded = false;

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'OnPremisesUserPrincipalName': sessionStorage.getItem('onPremisesUserPrincipalName') ?? ""
            }),
            body: JSON.stringify(mileAge)
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            isAdded = true;
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return isAdded;
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}

function mapJsonObjectToCompanyCar(jsonObject: any): Driver {
    return {
        contactId: jsonObject.ContactId,
        startDate: jsonObject.StartDate,
        endDate: jsonObject.EndDate,
        companyCar: {
            companyCarId: jsonObject.CompanyCar?.CompanyCarId,
            ownerId: jsonObject.CompanyCar?.OwnerId,
            licensePlate: jsonObject.CompanyCar?.LicensePlate,
            chassisNumber: jsonObject.CompanyCar?.ChassisNumber,
            firstRegistration: jsonObject.CompanyCar?.FirstRegistration,
            fuelType: jsonObject.CompanyCar?.FuelType,
            co2: jsonObject.CompanyCar?.Co2,
            type: jsonObject.CompanyCar?.Type,
            insuranceNumber: jsonObject.CompanyCar?.InsuranceNumber,
            fleetNumber: jsonObject.CompanyCar?.FleetNumber,
            franchise: jsonObject.CompanyCar?.Franchise,
            brand: {
                id: jsonObject.CompanyCar?.Brand?.Id,
                name: jsonObject.CompanyCar?.Brand?.Name,
            },
            mileAge: {
                date: jsonObject.CompanyCar?.MileAge?.Date,
                mileAgeNumber: jsonObject.CompanyCar?.MileAge?.MileAgeNumber
            }
        }
    }
}
