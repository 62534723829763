import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Button, ButtonGroup, Col, Label, Row, Table } from "reactstrap";
import EmployeeService from "../../Services/EmployeeService";
import "../GlobalStyles.css";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import ContractService from "../../Services/ContractService";
import Employee, { getStatuteTypeString, StatuteType } from "../../Models/Employee";
import UserHeaderComponent from "../UserHeaderComponent";
import Contract, { getContractTypeString } from "../../Models/Contract";
import { useMsal } from "@azure/msal-react";
import GraphService from '../../Services/GraphService';
import useWindowHeight from "../../Hooks/useWindowHeight";

export default function UserContractInfoPage() {
    const { windowHeight } = useWindowHeight();
    const { instance } = useMsal();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const contractService = useMemo(() => new ContractService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const history = useHistory();
    const [employee, setEmployee] = useState<Employee>();
    const [contracts, setContracts] = useState<Contract[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        getTimeTablesAndContracts();
    }, []);

    async function getTimeTablesAndContracts() {
        let user = await graphService.getUserDetails();
        let userName = user?.userPrincipalName as string;
        if (userName === null) {
            toast.error(`Kan geen gebruiker vinden.`);
            goBackToOverViewApp();
        }

        setIsLoading(true);

        employeeService.getEmployeeByUserName().then((data: Employee) => {
            if (!data) {
                toast.error(`Kan geen gebruiker met de gebruikersnaam ${userName} vinden.`);
                goBackToOverViewApp();
            } else {
                setEmployee(data);
            }
            return data;
        }).then((data: Employee) => {
            contractService.getContractsByEmployeeContactId(data.contactId).then((contracts: Contract[]) => {
                setContracts(contracts);
            }).finally(() => {
                setIsLoading(false);
            });
        }).catch(() => {
            setIsLoading(false);
        });
    }

    function getInputDateFormat(startDate: Date): string {
        if (startDate !== null) {
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
            const date: Date = new Date(startDate);
            const dateStringFormat: string = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
            return dateStringFormat;
        }
        return "";
    }

    function getDateInStringFormat(startDate: Date): string {
        if (startDate !== null) {
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
            const date: Date = new Date(startDate);
            const dateStringFormat: string = `${zeroPad(date.getDate(), 2)}-${zeroPad(date.getMonth() + 1, 2)}-${date.getFullYear()}`;
            return dateStringFormat;
        }
        return "";
    }

    function goBackToOverViewApp(): void {
        history.push("/overview");
    }

    async function goToContractDetailPage(contract: Contract): Promise<void> {
        setIsLoading(true);
        contractService.getSubContractsByPrimaryContractId(contract.contractId).then((subContracts: Contract[]) => {
            contract.subContracts = subContracts;
            
            history.push({
                pathname: '/user-contract-detail',
                state: { contract: contract }
            });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function noContractsFoundDiv() {
        return (
            <div className="pl-3">
                <Label>Geen contracten gevonden.</Label>
            </div>
        );
    }

    function getLoadSpinner() {
        return (
            <MoonLoader
                color="black"
                loading={isLoading}
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    top: 125px`
                }
                size={25} />
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/overview-user")}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/overview")}>
                        <i className="fas fa-home fa-2x"></i>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    function getGeneralDiv() {
        return (
            <div className="p-0 m-0 mt-3">
                <h5 className="mb-3"><b>Algemeen</b></h5>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Datum in dienst:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="date" value={getInputDateFormat(employee?.initialStartDate as Date)} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Statuut:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" value={getStatuteTypeString(employee?.statute as StatuteType)} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <hr color="#007bff"></hr>
            </div>
        );
    }

    function getContractsOverviewDiv() {
        return (
            <div className="p-0 m-0 mt-3">
                <h5 className="mb-3"><b>Contracten</b></h5>
                {contracts && contracts.length > 0 ?
                    <div>
                        <Table style={{ tableLayout: "fixed" }}>
                            <thead>
                                <tr>
                                    <th className="text-center">Type</th>
                                    <th className="text-center">Van</th>
                                    <th className="text-center">Tot en met</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contracts.map((contract, index) => {
                                    return (
                                        <>
                                            <tr key={index} onClick={() => goToContractDetailPage(contract)}>
                                                <td className="text-center p-1 align-middle">
                                                    <Label>{getContractTypeString(contract.type)}</Label>
                                                </td>
                                                <td className="text-center p-1 pl-2 align-middle">
                                                    <Label>{getDateInStringFormat(contract.from)}</Label>
                                                </td>
                                                <td className="text-center p-1 pl-2 align-middle">
                                                    <Label>{getDateInStringFormat(contract.until)}</Label>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody> 
                            
                        </Table>
                    </div>
                    : noContractsFoundDiv()}
            </div>
        );
    }

    return (
        <>
            {getLoadSpinner()}
            <UserHeaderComponent headerText={"Contract gegevens"} />

            {employee && contracts &&
                <>
                    <div style={{ height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}>
                        {getGeneralDiv()}
                        {getContractsOverviewDiv()}
                    </div>

                    {getButtonDiv()}
                </>
            }
        </>
    );
}