import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";
import AssetAllocation from "../Models/AssetAllocation";

export default class AssetService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getAssetsByEmployeeContactId(employeeContactId: string): Promise<AssetAllocation[]> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetAssetsByContactId`;
        let assetAllocations: AssetAllocation[] = [];
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            }),
            body: JSON.stringify(employeeContactId)
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    data.forEach((responseObject: any) => {
                        let assetAllocation: AssetAllocation = mapJsonObjectToAssetAllocation(responseObject)
                        assetAllocations.push(assetAllocation);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return assetAllocations;
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}

function mapJsonObjectToAssetAllocation(jsonObject: any): AssetAllocation {
    return {
        contactId: jsonObject.ContactId,
        startDate: jsonObject.StartDate,
        endDate: jsonObject.EndDate,
        remarks: jsonObject.Remarks,
        asset: {
            assetId: jsonObject.Asset?.AssetId,
            name: jsonObject.Asset?.Name,
            type: jsonObject.Asset?.Type,
            purchaseDate: jsonObject.Asset?.PurchaseDate,
            supplier: jsonObject.Asset?.Supplier,
            imei: jsonObject.Asset?.Imei,
            barCode: jsonObject.Asset?.BarCode,
            number: jsonObject.Asset?.Number,
            typeOfPhone: jsonObject.Asset?.TypeOfPhone,
            numberOfSim: jsonObject.Asset?.NumberOfSim,
            serialNumber: jsonObject.Asset?.SerialNumber
        }
    }
}
