import { useHistory } from "react-router-dom";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import AddToHomeScreenOutlinedIcon from '@mui/icons-material/AddToHomeScreenOutlined';
import { registerForPushNotifications } from "../../Helpers/OneSignalNotificationsHelper";
import { useEffect, useState } from "react";
import { isPushNotificationsEnabled } from "../../Helpers/OneSignalNotificationsHelper";
import SettingsItem from "../../Components/Settings/SettingsItem";
import useWindowHeight from "../../Hooks/useWindowHeight";
import UserHeaderComponent from "../UserHeaderComponent";
import { ButtonGroup, Button as ButtonReactStrap, ButtonToolbar, Modal, ModalBody, ModalHeader } from "reactstrap";

const Settings = () => {
    const { windowHeight } = useWindowHeight();
    const history = useHistory();
    const [arePushNotificationsEnabled, setArePushNotificationsEnabled] = useState<boolean>(false);
    const [isPushNotificationsPopupOpen, setIsPushNotificationsPopupOpen] = useState<boolean>(false);

    useEffect(() => {
        checkIfPushNotificationsAreEnabled();
    }, []);

    const checkIfPushNotificationsAreEnabled = () => {
        isPushNotificationsEnabled()
            .then((isEnabled: boolean) => {
                setArePushNotificationsEnabled(isEnabled);
            });
    }

    const getPushNotificationsPopup = () => {
        return (
            <Modal 
                isOpen={isPushNotificationsPopupOpen} 
                toggle={() => setIsPushNotificationsPopupOpen(false)} 
                centered
            >
                <ModalHeader className="d-flex justify-content-center">Push meldingen toestaan</ModalHeader>
                <ModalBody className="p-2">
                    Opgelet, op iOS (Apple apparaten) moet de app eerst op het beginscherm geplaatst worden voordat u deze functie kunt gebruiken. 
                    <br />
                    Gelieve de applicatie opnieuw op te starten voordat de meldingen in werking gaan.
                    <br />
                    <br />
                    <b>Wilt u de push meldingen toestaan?</b> 

                    <ButtonToolbar className="navbar p-0 mt-2">
                        <ButtonReactStrap 
                            color="primary" 
                            style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%" }} 
                            onClick={() => setIsPushNotificationsPopupOpen(false)}
                        >
                            Annuleer
                        </ButtonReactStrap>
                        <ButtonReactStrap 
                            color="primary" 
                            style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%" }} 
                            onClick={closePushNotificationsPopupAndRegister}
                        >
                            Ja
                        </ButtonReactStrap>
                    </ButtonToolbar>
                </ModalBody>
            </Modal>
        );
    }

    const closePushNotificationsPopupAndRegister = () => {
        registerForPushNotifications();
        setIsPushNotificationsPopupOpen(false);
    }

    function goBackToHomePage(): void {
        history.push("/");
    }

    function goToManualAddAppToHomeScreen(): void {
        history.push("/ManualAddAppToHomeScreen");
    }

    function getButtonFooterDiv() {
        return (
            <ButtonGroup className="fixed-bottom navbar p-0">
                <ButtonReactStrap 
                    className="p-3 buttons-footer" 
                    style={{ backgroundColor: "#2F5FA1" }} 
                    onClick={goBackToHomePage}
                >
                    <i className="fas fa-arrow-left fa-2x"></i>
                </ButtonReactStrap>
            </ButtonGroup>
        );
    }

    return (
        <>
            <UserHeaderComponent headerText={"Instellingen"} />
            {getPushNotificationsPopup()}

            <div className="m-0 ps-3 pe-3" 
                style={{height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}
            >
                <SettingsItem 
                    description="Push meldingen toestaan"
                    onClickFunction={
                        () => !arePushNotificationsEnabled && setIsPushNotificationsPopupOpen(true)
                    }
                    icon={
                        <NotificationsActiveOutlinedIcon 
                            className={arePushNotificationsEnabled ? "color-gold" : "color-white"} 
                            fontSize="large" 
                        />
                    }
                    isDisabled={arePushNotificationsEnabled}
                />
                <SettingsItem 
                    description="App op beginscherm plaatsen (instructies)"
                    onClickFunction={goToManualAddAppToHomeScreen}
                    icon={<AddToHomeScreenOutlinedIcon fontSize="large" />}
                    isDisabled={false}
                />
            </div>

            {getButtonFooterDiv()}
        </>
    )
}

export default Settings;