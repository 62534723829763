import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";
import WeeklyNewsItem from "../Models/WeeklyNewsItem";

export default class WeeklyNewsItemService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getAllWeeklyNewsItems(): Promise<WeeklyNewsItem[]> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetWeeklyNewsItems`;
        let weeklyNewsItems: WeeklyNewsItem[] = [];
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'OnPremisesUserPrincipalName': sessionStorage.getItem('onPremisesUserPrincipalName') ?? ""
            }),
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    data.forEach((responseObject: any) => {
                        let weeklyNewsItem: WeeklyNewsItem = mapJsonObjectToWeeklyNewsItem(responseObject)
                        weeklyNewsItems.push(weeklyNewsItem);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return weeklyNewsItems;
    }

    public async updateWeeklyNews(weeklyNews: WeeklyNewsItem) {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/UpdateWeeklyNews`;
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'OnPremisesUserPrincipalName': sessionStorage.getItem('onPremisesUserPrincipalName') ?? ""
            }),
            body: JSON.stringify(weeklyNews)
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }
        }).catch((error: Error) => {
            console.log(error.message)
        });
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}

function mapJsonObjectToWeeklyNewsItem(jsonObject: any): WeeklyNewsItem {
    return {
        id: jsonObject.Id,
        subject: jsonObject.Subject,
        message: jsonObject.Message,
        type: jsonObject.Type,
        createdOn: new Date(jsonObject.CreatedOn),
        fromDate: new Date(jsonObject.FromDate),
        untilDate: new Date(jsonObject.UntilDate),
        postedByEmployeeName: jsonObject.PostedByEmployeeName,
        editable: jsonObject.Editable
    };
}
