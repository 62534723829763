export default interface Asset {
    assetId: string;
    name: string;
    type: AssetType;
    purchaseDate: Date;
    supplier: string;
    imei: string;
    barCode: string;
    number: string;
    typeOfPhone: string;
    numberOfSim: string;
    serialNumber: string;
}

export enum AssetType
{
    MobieleTelefoon = 220950000,
    Sim = 220950001,
    PCLaptop = 220950002,
    Printer = 220950003,
    Badge = 220950004,
    Sleutels = 220950005,
    Telefoon = 220950006,
    Camera = 220950007,
    Medisch = 220950008,
}