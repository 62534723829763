import { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Col, Row } from "reactstrap";
import "../GlobalStyles.css";
import "../OverviewUser/OverviewUserPage.css";
import 'bootstrap/dist/css/bootstrap.css';
import { useHistory } from "react-router";
import EmployeeService from '../../Services/EmployeeService';
import Employee from "../../Models/Employee";
import { toast } from "react-toastify";
import UserHeaderComponent from "../UserHeaderComponent";
import { useMsal } from "@azure/msal-react";
import GraphService from '../../Services/GraphService';
import useWindowHeight from "../../Hooks/useWindowHeight";

export default function OverviewDocumentPage(props: any) {
    const { windowHeight } = useWindowHeight();
    const { instance } = useMsal();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const history = useHistory();
    const [, setEmployee] = useState<Employee>();

    useEffect(() => {
        getEmployee();
    }, []);

    async function getEmployee() {
        let user = await graphService.getUserDetails();
        let userName = user?.userPrincipalName as string;
        if (userName === null) {
            toast.error(`Kan geen gebruiker vinden.`);
            goBackToOverViewApp();
        }

        employeeService.getEmployeeByUserName().then((data: Employee) => {
            if (!data) {
                toast.error(`Kan geen gebruiker met de gebruikersnaam ${userName} vinden.`);
                goBackToOverViewApp();
            } else {
                setEmployee(data);
            }
        });
    }

    function goBackToOverViewApp(): void {
        history.push("/overview");
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/overview")}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    function getContent() {
        return (
            <div style={{ height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}>
                {/* <Row className="mx-auto p-3 mb-3 mt-3 button-row background-color-blue">
                    <Col xs="12" className="mx-auto d-flex align-items-center">
                        <h5 className="m-0">CLB Group</h5>
                    </Col>
                </Row>
                <Row className="mx-auto p-3 mb-3 button-row background-color-blue">
                    <Col xs="12" className="mx-auto d-flex align-items-center">
                        <h5 className="m-0">{employee?.partnership?.name}</h5>
                    </Col>
                </Row> */}
                <Row className="mx-auto p-3 mb-3 mt-3 button-row background-color-blue" onClick={() => history.push("/documents-personal")}>
                    <Col xs="12" className="mx-auto d-flex align-items-center">
                        <h5 className="m-0">Persoonlijk</h5>
                    </Col>
                </Row>
                <Row className="mx-auto p-3 mb-3 mt-3 button-row background-color-blue" onClick={() => history.push("/documents-uploaded")}>
                    <Col xs="12" className="mx-auto d-flex align-items-center">
                        <h5 className="m-0">Documenten opladen</h5>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <>
            <UserHeaderComponent headerText={"Documenten"} />

            {getContent()}
            {getButtonDiv()}
        </>
    );
}