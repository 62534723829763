import OneSignal from 'react-onesignal';

export const initPushNotifications = async () => {
    // console.log("initPushNotifications AppId");
    // console.log(process.env.REACT_APP_ONESIGNAL_APP_ID);
    await OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID ?? "",
    });
}

export const isPushNotificationsEnabled = async () => {
    return await OneSignal.isPushNotificationsEnabled();
}

export const registerForPushNotifications = async () => {
    await OneSignal.registerForPushNotifications();
}

// Set external user id so that OneSignal can target the correct device
export const setPushNotificationUserId = async (contactId: string) => {
    await OneSignal.setExternalUserId(contactId);
}

export const removePushNotificationUserId = async () => {
    await OneSignal.removeExternalUserId();
}