import { useMemo, useRef, useState } from 'react';
import Employee, { EmployeeGenderType, EmployeeLanguageType, EmployeeTravelType } from '../../Models/Employee';
import { Button, Col, Row, Form, Label, ButtonGroup } from 'reactstrap';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import City from '../../Models/City';
import SelectSearch from 'react-select-search';
import '../ReactSearchSelect.css';
import '../GlobalStyles.css';
import Country from '../../Models/Country';
import Nationality from '../../Models/Nationality';
import EmployeeService from '../../Services/EmployeeService';
import { toast } from 'react-toastify';
import MoonLoader from 'react-spinners/MoonLoader';
import { css } from "@emotion/react";
import { useMsal } from '@azure/msal-react';
import isValidPhoneNumber from 'libphonenumber-js'
import validator from 'validator'
import useWindowHeight from '../../Hooks/useWindowHeight';

interface Props {
    employee: Employee;
    cities: City[];
    birthPlaceCities: City[];
    countries: Country[];
    nationalities: Nationality[];
    props: any;
}

const UserGeneralInfoForm = (props: Props) => {
    const { windowHeight } = useWindowHeight();
    const { instance } = useMsal();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const history = useHistory();
    const cityRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const birthDayRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedCity, setSelectedCity] = useState<City>(props.employee.city);
    const [selectedBirthPlace, setSelectedBirthPlace] = useState<City>(props.employee.birthPlace);
    const [selectedCountry, setSelectedCountry] = useState<Country>(props.employee.country);
    const [selectedNationality, setSelectedNationality] = useState<Nationality>(props.employee.nationality);
    const [selectedDateOfBirth] = useState<Date>(() => {
        // This date is given back from the CRM if there is no date saved
        if (props.employee.dateOfBirth.getFullYear() === 1970 && props.employee.dateOfBirth.getMonth() === 0) {
            return null as any;
        }
        return props.employee.dateOfBirth;
    });
    const [isCityEmpty, setIsCityEmpty] = useState<boolean>(false);
    const [isBirthDayEmpty, setIsBirthDayEmpty] = useState<boolean>(false);
    const { register, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            employee: props.employee,
        }
    });

    async function onSubmit(data: any) {
        let isCityValid = true;
        let isBirthdayValid = true;

        if (selectedCity.name === undefined ||selectedCity.name === "") {
            setIsCityEmpty(true);
            isCityValid = false;
            cityRef.current.focus();
        } 

        if (selectedDateOfBirth === null || selectedDateOfBirth.toString() === "Invalid Date") {
            setIsBirthDayEmpty(true);
            isBirthdayValid = false;
            birthDayRef.current.focus();
        }

        if (isCityValid && isBirthdayValid) {
            setIsCityEmpty(false);
            setIsBirthDayEmpty(false);
            data.employee.city = selectedCity;
            data.employee.birthPlace = selectedBirthPlace;
            data.employee.country = selectedCountry;
            data.employee.nationality = selectedNationality;
            data.employee.dateOfBirth = selectedDateOfBirth;
            setIsLoading(true);
    
            employeeService.updateEmployee(data.employee).then(() => {
                setIsLoading(false);
                toast.success("Gegevens opgeslagen.");
            }).catch(() => {
                setIsLoading(false);
            });
        }
    }

    function isEmailValid(email: string): boolean {
        return validator.isEmail(email);
    }

    function isBankAccountValid(bankAccount: string): boolean {
        let bankAccountTrimmed = bankAccount.replaceAll(" ", "");
        return bankAccountTrimmed.trim().slice(0, 2).toUpperCase() === "BE" && bankAccountTrimmed.trim().length === 16;
    }

    const handleFilter = (items: any) => {
        return (searchValue: string) => {
            if (searchValue.length === 0) {
                return items;
            }

            const list: any[]= [];
            items.map((item: any) => {
                if (item.name.toLowerCase().includes(searchValue.toLowerCase())) {
                    list.push(item);
                }
            });
            return list;
        };
    };

    function handleChangeCity(...args: any) {
        if (args[1].name !== undefined && args[1].name !== "") {
            setIsCityEmpty(false);
        }
        setSelectedCity(args[1]);
    }

    function handleChangeBirthPlace(...args: any) {
        setSelectedBirthPlace(args[1]);
    }

    function handleChangeCountry(...args: any) {
        setSelectedCountry(args[1]);
    }

    function handleChangeNationality(...args: any) {
        setSelectedNationality(args[1]);
    }

    function getInputDateFormat(): string {
        if (selectedDateOfBirth !== null) {
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
            const date: Date = selectedDateOfBirth;
            const dateStringFormat: string = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
            return dateStringFormat;
        }
        return "";
    }

    function validatePhoneNumber(data: any) {
        const phoneNumber = isValidPhoneNumber(data, "BE");
        return phoneNumber?.isValid() === true;
    }

    function isFieldEmpty(data: any) {
        return data === null || data === undefined || data.trim() === "";
    }
    
    function validateEmail(data: any) {
        if (data === null || data === "") {
            return true;
        } else {
            return isEmailValid(data);
        }
    }

    function getTravelTypeString(employeeTravelType: EmployeeTravelType): string {
        switch(employeeTravelType) {
            case EmployeeTravelType.EigenVervoer:
                return "Eigen vervoer";
            case EmployeeTravelType.GeorganiseerdDoorDeWerkgever:
                return "Georganiseerd door de werkgever";
            default:
                return EmployeeTravelType[employeeTravelType];
        }
    }

    function getLoadSpinner() {
        return (
            <MoonLoader 
                color="black" 
                loading={isLoading} 
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    margin-top: 10px;
                    z-index: 9`
                } 
                size={25} />
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{backgroundColor: "#2F5FA1"}} onClick={() => history.push("/overview-user")} disabled={isLoading}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>                                 
                    <Button className="p-3 buttons-footer" style={{backgroundColor: "#2F5FA1"}} onClick={() => history.push("/overview")} disabled={isLoading}>
                        <i className="fas fa-home fa-2x"></i>
                    </Button>     
                    <Button className="p-3 buttons-footer" style={{backgroundColor: "#2F5FA1"}} onClick={handleSubmit(onSubmit)} disabled={isLoading}>
                        <i className="fas fa-save fa-2x"></i>
                    </Button>  
                </ButtonGroup>
            </>
        );
    }

    function getEmployeeDiv() {
        return (
            <div className="p-0 m-0 mt-3">
                {props.employee.hasNotApprovedChanges &&                 
                    <Row>
                        <div className="alert alert-warning" role="alert" style={{width:"100%"}}>
                            Uw wijzigingen zijn doorgestuurd. HR zal deze wijzigingen zo snel mogelijk bevestigen.
                        </div>
                    </Row>
                }
                <h5 className="mb-3"><b>Werknemer</b></h5>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">WN nr.:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("employee.employeeNumber")} className="inputs-fixed-height form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Gebruikersnaam:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("employee.userName")} className="inputs-fixed-height form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Werk e-mail:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("employee.internalEmailAddress")} className="inputs-fixed-height form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Vennootschap:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <input type="text" {...register("employee.partnership.name")} className="inputs-fixed-height form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Divisie:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <input type="text" {...register("employee.division.name")} className="inputs-fixed-height form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Afdeling:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <input type="text" {...register("employee.department.name")} className="inputs-fixed-height form-control" readOnly/>
                    </Col>
                </Row>
                <hr color="#007bff"></hr>
            </div>
        );
    }

    function getPersonalForm() {
        return (
            <div className="p-0 m-0 pb-1">
                <Form>
                    <h5 className="mb-3"><b>Persoonlijke gegevens</b></h5>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Naam:</Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <input type="text" {...register("employee.lastName", { required: true})} className="inputs-fixed-height form-control" readOnly/>
                            </div>
                            {errors.employee?.lastName && (
                                <Label style={{color: "red"}}>Veld moet ingevuld zijn</Label>
                            )}
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Voornaam:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("employee.firstName")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Straat:<span style={{color: "red"}}>*</span></Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <input type="text" {...register("employee.street", { required: true, validate: (value) => !isFieldEmpty(value)})}  className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {errors.employee?.street && (
                                <Label style={{color: "red"}}>Veld moet ingevuld zijn</Label>
                            )}
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Huisnr.:<span style={{color: "red"}}>*</span></Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <input type="text" {...register("employee.houseNumber", { required: true, validate: (value) => !isFieldEmpty(value)})}  className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {errors.employee?.houseNumber && (
                                <Label style={{color: "red"}}>Veld moet ingevuld zijn</Label>
                            )}
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Busnr.:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("employee.postBox")} className="inputs-fixed-height form-control"/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Postcode:</Label>
                        </Col>
                        <Col xs="12">
                            <input ref={cityRef} type="text" value={selectedCity?.postalCode} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Gemeente:<span style={{color: "red"}}>*</span></Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <SelectSearch options={props.cities} onChange={handleChangeCity} value={selectedCity?.name} filterOptions={handleFilter} search/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {isCityEmpty && (
                                <Label style={{color: "red"}}>Veld moet ingevuld zijn</Label>
                            )}
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Land:</Label>
                        </Col>
                        <Col xs="12">
                            <SelectSearch options={props.countries} onChange={handleChangeCountry} value={selectedCountry?.name} filterOptions={handleFilter} search/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Geboortedatum:</Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <input ref={birthDayRef} type="date" value={getInputDateFormat()} className="inputs-fixed-height form-control" readOnly/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {isBirthDayEmpty && (
                                <Label style={{color: "red"}}>Veld moet ingevuld zijn</Label>
                            )}
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Geboorteplaats:</Label>
                        </Col>
                        <Col xs="12">
                            <SelectSearch options={props.birthPlaceCities} onChange={handleChangeBirthPlace} value={selectedBirthPlace?.name} filterOptions={handleFilter} search/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Nationaliteit:</Label>
                        </Col>
                        <Col xs="12">
                            <SelectSearch options={props.nationalities} onChange={handleChangeNationality} value={selectedNationality?.name} filterOptions={handleFilter} search/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Taal:</Label>
                        </Col>
                        <Col xs="12">
                            <select {...register("employee.language")} className="inputs-fixed-height form-control form-control-md p-0 pl-2">
                                <option value={EmployeeLanguageType.Nederlands}>{EmployeeLanguageType[EmployeeLanguageType.Nederlands]}</option>
                                <option value={EmployeeLanguageType.Frans}>{EmployeeLanguageType[EmployeeLanguageType.Frans]}</option>
                                <option value={EmployeeLanguageType.Duits}>{EmployeeLanguageType[EmployeeLanguageType.Duits]}</option>
                                <option value={EmployeeLanguageType.Engels}>{EmployeeLanguageType[EmployeeLanguageType.Engels]}</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Geslacht:</Label>
                        </Col>
                        <Col xs="12">
                            <select {...register("employee.gender")} className="inputs-fixed-height form-control form-control-md p-0 pl-2">
                                <option value={EmployeeGenderType.Man}>{EmployeeGenderType[EmployeeGenderType.Man]}</option>
                                <option value={EmployeeGenderType.Vrouw}>{EmployeeGenderType[EmployeeGenderType.Vrouw]}</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Bankrekeningnr.:<span style={{color: "red"}}>*</span></Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <input type="text" {...register("employee.bankAccount", { required: true, validate: (value) => isBankAccountValid(value)})} className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                    </Row>  
                    <Row>
                        <Col>
                            {errors.employee?.bankAccount && (
                                <Label style={{color: "red"}}>Ongeldig bankrekeningnummer</Label>
                            )}
                        </Col>
                    </Row>
                    <hr color="#007bff"></hr>

                    <h5 className="mb-3"><b>Contact gegevens</b></h5>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Vaste telefoon:</Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <input type="text" {...register("employee.fixedTelephone", { validate: (value) => isFieldEmpty(value) || validatePhoneNumber(value) })} className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {errors.employee?.fixedTelephone && (
                                <Label style={{color: "red"}}>Ongeldig telefoonnummer</Label>
                            )}
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Mobiele telefoon:</Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <input type="text" {...register("employee.mobilePhone", { validate: (value) => isFieldEmpty(value) || validatePhoneNumber(value) })} className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {errors.employee?.mobilePhone && (
                                <Label style={{color: "red"}}>Ongeldig gsmnnummer</Label>
                            )}
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">E-mail:</Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <input type="text" {...register("employee.privateEmailAddress", { validate: (value) => validateEmail(value) })} className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                    </Row>  
                    <Row>
                        <Col>
                            {errors.employee?.privateEmailAddress && (
                                <Label style={{color: "red"}}>Ongeldig e-mailadres</Label>
                            )}
                        </Col>
                    </Row>
                    <hr color="#007bff"></hr>

                    <h5 className="mb-3"><b>Woonplaats-werkverkeer</b></h5>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Vervoerstype:</Label>
                        </Col>
                        <Col xs="12">
                            <select {...register("employee.travelType")} className="inputs-fixed-height form-control form-control-md p-0 pl-2">
                                <option value={EmployeeTravelType.EigenVervoer}>{getTravelTypeString(EmployeeTravelType.EigenVervoer)}</option>
                                <option value={EmployeeTravelType.Bus}>{getTravelTypeString(EmployeeTravelType.Bus)}</option>
                                <option value={EmployeeTravelType.Fiets}>{getTravelTypeString(EmployeeTravelType.Fiets)}</option>
                                <option value={EmployeeTravelType.Tram}>{getTravelTypeString(EmployeeTravelType.Tram)}</option>
                                <option value={EmployeeTravelType.Trein}>{getTravelTypeString(EmployeeTravelType.Trein)}</option>
                                <option value={EmployeeTravelType.GeorganiseerdDoorDeWerkgever}>{getTravelTypeString(EmployeeTravelType.GeorganiseerdDoorDeWerkgever)}</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">KM woon/werk enkel:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="number" {...register("employee.kmWorkHome")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <hr color="#007bff"></hr>

                    <h5 className="mb-3"><b>Noodnummers</b></h5>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Noodcontact 1:<span style={{color: "red"}}>*</span></Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <input type="text" {...register("employee.emergencyContact1", { required: true, validate: (value) => !isFieldEmpty(value)})} className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {errors.employee?.emergencyContact1 && (
                                <Label style={{color: "red"}}>Veld moet ingevuld zijn</Label>
                            )}
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Verwantschap 1:<span style={{color: "red"}}>*</span></Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <input type="text" {...register("employee.emergencyContactLink1", { required: true, validate: (value) => !isFieldEmpty(value) })} className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {errors.employee?.emergencyContactLink1 && (
                                <Label style={{color: "red"}}>Veld moet ingevuld zijn</Label>
                            )}
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Noodcontact Nr. 1:<span style={{color: "red"}}>*</span></Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <input type="text" {...register("employee.emergencyContactNumber1", { required: true, validate: (value) => !isFieldEmpty(value) && validatePhoneNumber(value) })} className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {errors.employee?.emergencyContactNumber1 && (
                                <Label style={{color: "red"}}>Ongeldig gsm/telefoonnummer</Label>
                            )}
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Noodcontact 2:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("employee.emergencyContact2")} className="inputs-fixed-height form-control"/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Verwantschap 2:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("employee.emergencyContactLink2")} className="inputs-fixed-height form-control"/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Noodcontact Nr. 2:</Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <input type="text" {...register("employee.emergencyContactNumber2", { validate: (value) => isFieldEmpty(value) || validatePhoneNumber(value) })} className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {errors.employee?.emergencyContactNumber2 && (
                                <Label style={{color: "red"}}>Ongeldig gsm/telefoonnummer</Label>
                            )}
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Noodcontact 3:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("employee.emergencyContact3")} className="inputs-fixed-height form-control"/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Verwantschap 3:</Label> 
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("employee.emergencyContactLink3")} className="inputs-fixed-height form-control"/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-3 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Noodcontact Nr. 3:</Label>
                        </Col>
                        <Col xs="12">
                            <div>
                                <input type="text" {...register("employee.emergencyContactNumber3", { validate: (value) => isFieldEmpty(value) || validatePhoneNumber(value) })} className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {errors.employee?.emergencyContactNumber3 && (
                                <Label style={{color: "red"}}>Ongeldig gsm/telefoonnummer</Label>
                            )}
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
    
    return (
        <>
            {getButtonDiv()}
            {getLoadSpinner()}

            <div style={{height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}>
                {getEmployeeDiv()}
                {getPersonalForm()}
            </div>
        </>
    );
};

export default UserGeneralInfoForm;