import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import EmployeeService from "../../Services/EmployeeService";
import "../GlobalStyles.css";
import EmployeeFamily from "../../Models/EmployeeFamily";
import { toast } from "react-toastify";
import DriverService from "../../Services/DriverService";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import Driver from "../../Models/Driver";
import UserHeaderComponent from "../UserHeaderComponent";
import { useMsal } from "@azure/msal-react";
import GraphService from '../../Services/GraphService';
import UserCompanyCarForm from './UserCompanyCarForm';

export default function UserCompanyCarPage(props: any) {
    const { instance } = useMsal();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const driverService = useMemo(() => new DriverService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const history = useHistory();
    const [driver, setDriver] = useState<Driver>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        getCompanyCar();
    }, []);

    async function getCompanyCar() {
        let user = await graphService.getUserDetails();
        let userName = user?.userPrincipalName as string;
        if (userName === null) {
            toast.error(`Kan geen gebruiker vinden.`);
            goBackToOverViewApp();
        }

        setIsLoading(true);

        employeeService.getEmployeeFamilyByUserName(userName).then((data: EmployeeFamily) => {
            if (!data) {
                toast.error(`Kan geen gebruiker met de gebruikersnaam ${userName} vinden.`);
                goBackToOverViewApp();
            } 
            return data;
        }).then((data: EmployeeFamily) => {
            driverService.getDriverByEmployeeContactId(data.contactId).then((driver: Driver) => {
                setDriver(driver);
            }).finally(() => {
                setIsLoading(false);
            });
        }).catch(() => {
            setIsLoading(false);
        });
    }

    function goBackToOverViewApp(): void {
        history.push("/overview");
    }

    function getLoadSpinner() {
        return (
            <MoonLoader
                color="black"
                loading={!driver || isLoading}
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    top: 125px; 
                    z-index: 1000`
                }
                size={25} />
        );
    }

    function getPersonalDataDiv() {
        return (
            <div className="p-0 m-0">
                {driver &&
                    <UserCompanyCarForm 
                        driver={driver} 
                        props={props} 
                        driverService={driverService} 
                        getCompanyCar={getCompanyCar} 
                        setIsLoading={setIsLoading} 
                    />
                }
            </div>
        );
    }

    return (
        <>
            {getLoadSpinner()}
            <UserHeaderComponent headerText={"Gegevens bedrijfswagen"} />

            {driver &&
                <>
                    {getPersonalDataDiv()}
                </>
            }
        </>
    );
}