export default interface DocumentFile {
    fileName: string;
    fileNameWithPrefix: string;
    fileExtension: string;
    fileBytes: string;
    contentType: string;
    typeDocument: TypeDocument;
    typeDocumentName:string;
    typeAbsenceCertificate: TypeAbsenceCertificate;
    createdDate: Date;
    isInUploadFolder: boolean;
}

export enum TypeDocument {
    Afwezigheidsattest = 220950000,
    Rijbewijs = 220950001
}

export enum TypeAbsenceCertificate {
    Ziekte = 220950000,
    KleinVerlet = 220950001,
    Opleiding = 220950002,
    FamiliaalVerlof = 220950003
}

export function translateTypeAbsenceCertificate(typeAbsenceCertificate: TypeAbsenceCertificate): string {
    switch(typeAbsenceCertificate) {
        case TypeAbsenceCertificate.KleinVerlet:
            return "Klein verlet";
        case TypeAbsenceCertificate.FamiliaalVerlof:
            return "Familiaal verlof";
        default:
            return TypeAbsenceCertificate[typeAbsenceCertificate];
    }
}