import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";
import Contract from "../Models/Contract";

export default class ContractService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getContractsByEmployeeContactId(employeeContactId: string): Promise<Contract[]> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetContractByContactId`;
        let contracts: Contract[] = [];
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,

            }),
            body: JSON.stringify(employeeContactId)
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    data.forEach((responseObject: any) => {
                        let contract: Contract = mapJsonObjectToContract(responseObject)
                        contracts.push(contract);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return contracts;
    }

    public async getSubContractsByPrimaryContractId(contractId: string): Promise<Contract[]> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetSubContractsByContractId`;
        let contracts: Contract[] = [];
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,

            }),
            body: JSON.stringify(contractId)
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    data.forEach((responseObject: any) => {
                        let contract: Contract = mapJsonObjectToContract(responseObject)
                        contracts.push(contract);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return contracts;
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}

function mapJsonObjectToContract(jsonObject: any): Contract {
    return {
        contractId: jsonObject.ContractId,
        contactId: jsonObject.ContactId,
        primaryContractId: jsonObject.PrimaryContractId,
        type: jsonObject.Type,
        from: jsonObject.From,
        startDate: jsonObject.StartDate,
        until: jsonObject.Until,
        paritairComite: jsonObject.ParitairComite,
        jobtime: jsonObject.Jobtime,
        replacementOf: jsonObject.ReplacementOf,
        subContracts: null as any,
        function: {
            id: jsonObject.Function?.ConfigurationId,
            name: jsonObject.Function?.Name
        },
        functionDescription: jsonObject.FunctionDescription
    }
}
