export default interface WeeklyNewsItem {
    id?: string;
    subject: string;
    message: string;
    type: WeeklyNewsItemType;
    targetGroup?: TargetGroup;
    createdOn?: Date;
    fromDate?: Date;
    untilDate?: Date;
    postedByEmployeeName?: string;
    editable?: boolean;
}

export enum WeeklyNewsItemType {
    Birthday = 220950000,
    Birth = 220950003,
    Seniority = 220950001,
    Event = 220950002,
    Diverse = 220950004,
}

export enum TargetGroup {
    AllEmployees,
    MyCompany,
    MyDivision,
    MyDepartment,
}

export const getTargetGroupString = (targetGroup: TargetGroup): string => {
    switch (targetGroup) {
        case TargetGroup.AllEmployees:
            return "Alle medewerkers";
        case TargetGroup.MyCompany:
            return "Mijn vennootschap";
        case TargetGroup.MyDivision:
            return "Mijn divisie";
        case TargetGroup.MyDepartment:
            return "Mijn afdeling";
    }
}