import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Button, ButtonGroup, Col, Label, Row } from "reactstrap";
import "../GlobalStyles.css";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import AssetAllocation from "../../Models/AssetAllocation";
import { AssetType } from "../../Models/Asset";
import { useLocation } from "react-router-dom";
import UserHeaderComponent from "../UserHeaderComponent";
import { useMsal } from '@azure/msal-react';
import GraphService from '../../Services/GraphService';
import useWindowHeight from "../../Hooks/useWindowHeight";

export default function UserAssetDetailPage(props: any) {
    const { windowHeight } = useWindowHeight();
    const { instance } = useMsal();
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const history = useHistory();
    const location = useLocation();
    const [assetAllocation, setAssetAllocation] = useState<AssetAllocation>();

    useEffect(() => {
        getAssets();
    }, []);

    async function getAssets() {
        let user = await graphService.getUserDetails();
        let userName = user?.userPrincipalName as string;
        if (userName === null) {
            toast.error(`Kan geen gebruiker vinden.`);
            goBackToAssetsOverviewPage();
        }

        if (!location.state) {
            toast.error("Werkmiddel niet gevonden.");
            goBackToAssetsOverviewPage();
        }

        let assetAllocation = location.state as AssetAllocation;
        setAssetAllocation(assetAllocation);
    }

    function getInputDateFormat(dateString: Date): string {
        if (dateString !== null) {
            const startDate = new Date(dateString);
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
            const date: Date = startDate;
            const dateStringFormat: string = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
            return dateStringFormat;
        }
        return "";
    }

    function getStatuteTypeString(assetType: AssetType): string {
        switch (assetType) {
            case AssetType.MobieleTelefoon:
                return "Mobiele telefoon";
            case AssetType.PCLaptop:
                return "PC/Laptop";
            default:
                return AssetType[assetType];
        }
    }

    function goBackToAssetsOverviewPage(): void {
        history.push("/user-assets-overview");
    }

    function getTypeOfAsset(): AssetType {
        return assetAllocation?.asset.type || null as any;
    }

    function getLoadSpinner() {
        return (
            <MoonLoader
                color="black"
                loading={!assetAllocation}
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    top: 125px`
                }
                size={25} />
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={goBackToAssetsOverviewPage}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/overview")}>
                        <i className="fas fa-home fa-2x"></i>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    function getGeneralDiv() {
        return (
            <div className="p-0 m-0 mt-3">
                {assetAllocation ?
                    <>
                        <h5 className="mb-3"><b>Algemeen</b></h5>
                        <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                            <Col xs="12" className="mb-1">
                                <Label className="m-0">Type:</Label>
                            </Col>
                            <Col xs="12">
                                <input type="text" value={getStatuteTypeString(assetAllocation.asset.type)} className="inputs-fixed-height form-control" readOnly />
                            </Col>
                        </Row>
                        <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                            <Col xs="12" className="mb-1">
                                <Label className="m-0">Naam:</Label>
                            </Col>
                            <Col xs="12">
                                <input type="text" value={assetAllocation.asset.name} className="inputs-fixed-height form-control" readOnly />
                            </Col>
                        </Row>
                        <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                            <Col xs="12" className="mb-1">
                                <Label className="m-0">Barcode:</Label>
                            </Col>
                            <Col xs="12">
                                <input type="text" value={assetAllocation.asset.barCode} className="inputs-fixed-height form-control" readOnly />
                            </Col>
                        </Row>
                        <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                            <Col xs="12" className="mb-1">
                                <Label className="m-0">Leverancier:</Label>
                            </Col>
                            <Col xs="12">
                                <input type="text" value={assetAllocation.asset.supplier} className="inputs-fixed-height form-control" readOnly />
                            </Col>
                        </Row>
                        <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                            <Col xs="12" className="mb-1">
                                <Label className="m-0">Aankoopdatum:</Label>
                            </Col>
                            <Col xs="12">
                                <input type="date" value={getInputDateFormat(assetAllocation.asset.purchaseDate)} className="inputs-fixed-height form-control" readOnly />
                            </Col>
                        </Row>
                        <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                            <Col xs="12" className="mb-1">
                                <Label className="m-0">Startdatum:</Label>
                            </Col>
                            <Col xs="12">
                                <input type="date" value={getInputDateFormat(assetAllocation.startDate)} className="inputs-fixed-height form-control" readOnly />
                            </Col>
                        </Row>
                        <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                            <Col xs="12" className="mb-1">
                                <Label className="m-0">Einddatum:</Label>
                            </Col>
                            <Col xs="12">
                                <input type="date" value={getInputDateFormat(assetAllocation.endDate)} className="inputs-fixed-height form-control" readOnly />
                            </Col>
                        </Row>
                        <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                            <Col xs="12" className="mb-1">
                                <Label className="m-0">Opmerking:</Label>
                            </Col>
                            <Col xs="12">
                                <input type="text" value={assetAllocation.remarks} className="inputs-fixed-height form-control" readOnly />
                            </Col>
                        </Row>
                    </>
                    : ""}
            </div>
        );
    }

    function getExtraDiv() {
        return (
            <div className="p-0 m-0 mt-3">
                {assetAllocation && getTypeOfAsset() !== AssetType.Sleutels && getTypeOfAsset() !== AssetType.Medisch ?
                    <>
                        <h5 className="mb-3"><b>Extra</b></h5>
                        {getTypeOfAsset() === AssetType.PCLaptop || getTypeOfAsset() === AssetType.Badge ?
                            <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                                <Col xs="12" className="mb-1">
                                    <Label className="m-0">Nummer:</Label>
                                </Col>
                                <Col xs="12">
                                    <input type="text" value={assetAllocation.asset.number} className="inputs-fixed-height form-control" readOnly />
                                </Col>
                            </Row>
                            : ""}

                        {getTypeOfAsset() === AssetType.PCLaptop || getTypeOfAsset() === AssetType.Printer ||
                            getTypeOfAsset() === AssetType.Telefoon || getTypeOfAsset() === AssetType.Camera ?
                            <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                                <Col xs="12" className="mb-1">
                                    <Label className="m-0">Serienummer:</Label>
                                </Col>
                                <Col xs="12">
                                    <input type="text" value={assetAllocation.asset.serialNumber} className="inputs-fixed-height form-control" readOnly />
                                </Col>
                            </Row>
                            : ""}
                        {getTypeOfAsset() === AssetType.Sim ?
                            <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                                <Col xs="12" className="mb-1">
                                    <Label className="m-0">Simkaart nummer:</Label>
                                </Col>
                                <Col xs="12">
                                    <input type="text" value={assetAllocation.asset.numberOfSim} className="inputs-fixed-height form-control" readOnly />
                                </Col>
                            </Row>
                            : ""}
                        {getTypeOfAsset() === AssetType.MobieleTelefoon ?
                            <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                                <Col xs="12" className="mb-1">
                                    <Label className="m-0">Type telefoon:</Label>
                                </Col>
                                <Col xs="12">
                                    <input type="text" value={assetAllocation.asset.typeOfPhone} className="inputs-fixed-height form-control" readOnly />
                                </Col>
                            </Row>
                            : ""}
                        {getTypeOfAsset() === AssetType.MobieleTelefoon ?
                            <Row className="pl-2 pr-2 mb-3 d-flex align-items-center">
                                <Col xs="12" className="mb-1">
                                    <Label className="m-0">IMEI:</Label>
                                </Col>
                                <Col xs="12">
                                    <input type="text" value={assetAllocation.asset.imei} className="inputs-fixed-height form-control" readOnly />
                                </Col>
                            </Row>
                            : ""}
                    </>
                    : ""}
            </div>
        );
    }

    return (
        <>
            {getLoadSpinner()}
            <UserHeaderComponent headerText={"Gegevens werkmiddelen"} />

            {assetAllocation &&
                <>
                    <div style={{ height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}>
                        {getGeneralDiv()}
                        {getExtraDiv()}
                        {getButtonDiv()}
                    </div>
                </>
            }
        </>
    );
}