import { useMemo, useState } from 'react';
import { Button, Col, Row, Table, Form, Label, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, ButtonToolbar } from 'reactstrap';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import EmployeeService from '../../Services/EmployeeService';
import EmployeeFamily, { FamilyStatusType, PartnerStatuteType } from '../../Models/EmployeeFamily';
import "../GlobalStyles.css";
import Child from '../../Models/Child';
import { toast } from 'react-toastify';
import MoonLoader from 'react-spinners/MoonLoader';
import { css } from "@emotion/react";
import { useMsal } from '@azure/msal-react';
import useWindowHeight from '../../Hooks/useWindowHeight';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

interface Props {
    employeeFamily: EmployeeFamily;
    props: any;
}

const UserFamilyForm = (props: Props) => {
    const { windowHeight } = useWindowHeight();
    const { instance } = useMsal();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedMaritalStateDate, setSelectedMaritalStateDate] = useState<Date>(props.employeeFamily.maritalStateDate);
    const [selectedPartnerBirthDate, setSelectedPartnerBirthDate] = useState<Date>(props.employeeFamily.partnerBirthDate);
    const [children, setChildren] = useState<Child[]>(props.employeeFamily.children);
    const [showNewChildPopup, setShowNewChildPopup] = useState(false);
    const [showEditChildPopup, setShowEditChildPopup] = useState(false);
    const [editChildIndex, setEditChildIndex] = useState<number>(-1);
    const [newChild, setNewChild] = useState<Child>({lastName: "", firstName: "", birthDate: new Date(), childId: null as any, contactId: props.employeeFamily.contactId});
    const [editChild, setEditChild] = useState<Child>({lastName: "", firstName: "", birthDate: new Date(), childId: null as any, contactId: props.employeeFamily.contactId});
    const { register, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            employeeFamily: props.employeeFamily,
        }
    });

    async function onSubmit(data: any) {
        data.employeeFamily.maritalStateDate = selectedMaritalStateDate;
        data.employeeFamily.partnerBirthDate = selectedPartnerBirthDate;
        data.employeeFamily.children = children;

        setIsLoading(true);
        employeeService.updateEmployeeFamily(data.employeeFamily).then(() => {
            employeeService.getChildrenByEmployeeContactId(data.employeeFamily.contactId).then((children) => {
                setChildren(children);
            }).catch(() => {
                console.log("Kinderen konden niet worden opgehaald.")
            }).finally(() => {
                setIsLoading(false);
                toast.success("Gegevens opgeslagen.");
            });
        }).catch(() => {
            setIsLoading(false);
        });
    }

    function getMaritalStateInputDateFormat(): string {
        if (selectedMaritalStateDate !== null) {
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');
            const date: Date = new Date(selectedMaritalStateDate);
            const dateStringFormat: string = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
            return dateStringFormat;
        }
        return "";
    }

    function handleChangeMaritalStateDate(event: any) {
        setSelectedMaritalStateDate(new Date(event.target.value));
    }

    function getPartnerBirthDateInputDateFormat(): string {
        if (selectedPartnerBirthDate !== null) {
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');
            const date: Date = new Date(selectedPartnerBirthDate);
            const dateStringFormat: string = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
            return dateStringFormat;
        }
        return "";
    }

    function handleChangePartnerBirthDate(event: any) {
        setSelectedPartnerBirthDate(new Date(event.target.value));
    }

    function getInputDateFormat(startDate: Date): string {
        if (startDate !== null) {
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
            const date: Date = new Date(startDate);
            const dateStringFormat: string = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
            return dateStringFormat;
        }
        return "";
    }

    function getInputDateFormatString(startDate: Date): string {
        if (startDate !== null) {
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
            const date: Date = new Date(startDate);
            const dateStringFormat: string = `${zeroPad(date.getDate(), 2)}-${zeroPad(date.getMonth() + 1, 2)}-${date.getFullYear()}`;
            return dateStringFormat;
        }
        return "";
    }


    function addChild(): void {
        if (!newChild?.lastName) {
            toast.error("Naam is verplicht.");
            return;
        }

        const updatedChildren = children.slice(0);
        updatedChildren.push({...newChild});
        setChildren(updatedChildren);
        setShowNewChildPopup(false);
    }

    function editChildAndAddToList() {
        if (!editChild?.lastName) {
            toast.error("Naam is verplicht.");
            return;
        }
        
        const updatedChildren = children.slice(0);
        updatedChildren[editChildIndex] = editChild;
        setChildren(updatedChildren);
        setShowEditChildPopup(false);
    }

    function removeChildByIndex(index: number): void {
        const updatedChildren = children.slice(0);
        updatedChildren.splice(index, 1);
        setChildren(updatedChildren)
    }
    
    function removeChildById(childId: string): void {
        const updatedChildren = children.slice(0);
        const index = updatedChildren.findIndex(child => child.childId === childId);
        updatedChildren.splice(index, 1);
        setChildren(updatedChildren)
        setShowEditChildPopup(false);
    }

    function handleEditChildChange(property: string, data: any): void {
        const updatedChild = {...editChild};
        (updatedChild as any)[property] = data;
        setEditChild(updatedChild as Child);
    }

    function handleNewChildChange(property: string, data: any): void {
        const updatedChild = {...newChild};
        (updatedChild as any)[property] = data;
        setNewChild(updatedChild);
    }

    function getPartnerStatuteTypeString(partnerStatuteType: PartnerStatuteType): string {
        switch(partnerStatuteType) {
            case PartnerStatuteType.VasteBeamteOfStagiair:
                return "Vaste beamte of stagiair";
            default:
                return PartnerStatuteType[partnerStatuteType];
        }
    }

    function getFamilyStatusTypeString(familyStatusType: FamilyStatusType): string {
        switch(familyStatusType) {
            case FamilyStatusType.FeitelijkOfVanTafelEnBedGescheiden:
                return "Feitelijk of van tafel en bed gescheiden";
            case FamilyStatusType.WeduweOfWeduwenaar:
                return "Weduwe of weduwenaar";
            case FamilyStatusType.WettelijkGescheiden:
                return "Wettelijk gescheiden";
            case FamilyStatusType.WettelijkSamenwonend:
                return "Wettelijk samenwonend";
            default:
                return FamilyStatusType[familyStatusType];
        }
    }

    function openEditChildPopup(child: Child, index: number) {
        setEditChild(child);
        setEditChildIndex(index);
        setShowEditChildPopup(true);
    }

    function openNewChildPopup() {
        setNewChild({lastName: "", firstName: "", birthDate: new Date(), childId: null as any, contactId: props.employeeFamily.contactId})
        setShowNewChildPopup(true);
    }

    function getNewChildPopup() {
        return (
            <Modal isOpen={showNewChildPopup} toggle={() => setShowNewChildPopup(false)} centered>
                <ModalHeader className="d-flex justify-content-center">
                    Kind toevoegen

                    <IconButton 
                        style={{ position: "absolute", top: "5px", right: "5px" }}
                        onClick={() => setShowNewChildPopup(false)}    
                    >
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                </ModalHeader>
                <ModalBody className="p-2">
                    <Row className="pl-2 pr-2 mb-1">
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Naam: <span style={{color: "red"}}>*</span></Label>
                            <input type="text" value={newChild.lastName ?? ""} onChange={(data) => handleNewChildChange("lastName", data.target.value)} className="inputs-fixed-height form-control"/>
                        </Col>
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Voornaam:</Label>
                            <input type="text" value={newChild.firstName ?? ""} onChange={(data) => handleNewChildChange("firstName", data.target.value)} className="inputs-fixed-height form-control"/>
                        </Col>
                        <Col xs="12" className="pl-3 mb-3 d-flex flex-column justify-content-end">
                            <Label>Geboortedatum:</Label>
                            <input type="date" value={getInputDateFormat(newChild.birthDate)} onChange={(data) => handleNewChildChange("birthDate", new Date(data.target.value))} className="inputs-fixed-height form-control"/>
                        </Col>
                    </Row>
                    <ButtonToolbar className="navbar p-0 justify-content-end">
                        <Button 
                            color="primary" 
                            style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "98%" }} 
                            onClick={addChild}
                        >
                            Opslaan
                        </Button>
                    </ButtonToolbar>
                </ModalBody>
            </Modal>
        );
    }

    function getEditChildPopup() {
        return (
            <Modal isOpen={showEditChildPopup} toggle={() => setShowEditChildPopup(false)} centered>
                <ModalHeader className="d-flex justify-content-center">
                    Kind bewerken

                    <IconButton 
                        style={{ position: "absolute", top: "5px", right: "5px" }}
                        onClick={() => setShowEditChildPopup(false)}    
                    >
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                </ModalHeader>
                <ModalBody className="p-2">
                    <Row className="pl-2 pr-2 mb-1">
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Naam: <span style={{color: "red"}}>*</span></Label>
                            <input type="text" value={editChild.lastName ?? ""} onChange={(data) => handleEditChildChange("lastName", data.target.value)} className="inputs-fixed-height form-control"/>
                        </Col>
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Voornaam:</Label>
                            <input type="text" value={editChild.firstName ?? ""} onChange={(data) => handleEditChildChange("firstName", data.target.value)} className="inputs-fixed-height form-control"/>
                        </Col>
                        <Col xs="12" className="pl-3 mb-3 d-flex flex-column justify-content-end">
                            <Label>Geboortedatum:</Label>
                            <input type="date" value={getInputDateFormat(editChild.birthDate)} onChange={(data) => handleEditChildChange("birthDate", new Date(data.target.value))} className="inputs-fixed-height form-control"/>
                        </Col>
                    </Row>
                    <ButtonToolbar className="navbar p-0">
                        <Button 
                            color="primary" 
                            style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%" }} 
                            onClick={() => removeChildById(editChild.childId)}
                        >
                            Verwijderen
                        </Button>
                        <Button 
                            color="primary" 
                            style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%" }} 
                            onClick={editChildAndAddToList}
                        >
                            Opslaan
                        </Button>
                    </ButtonToolbar>
                </ModalBody>
            </Modal>
        );
    }

    function getLoadSpinner() {
        return (
            <MoonLoader 
                color="black" 
                loading={isLoading} 
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    margin-top: 10px;
                    z-index: 9`
                } 
                size={25} />
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{backgroundColor: "#2F5FA1"}} onClick={() => history.push("/overview-user")} disabled={isLoading}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>                                 
                    <Button className="p-3 buttons-footer" style={{backgroundColor: "#2F5FA1"}} onClick={() => history.push("/overview")} disabled={isLoading}>
                        <i className="fas fa-home fa-2x"></i>
                    </Button>   
                    <Button className="p-3 buttons-footer" style={{backgroundColor: "#2F5FA1"}} onClick={handleSubmit(onSubmit)} disabled={isLoading}>
                        <i className="fas fa-save fa-2x"></i>
                    </Button>    
                </ButtonGroup>
            </>
        );
    }

    function getPersonalForm() {
        return (
            <div className="p-0 m-0 mt-3">
                {getNewChildPopup()}
                {getEditChildPopup()}
                {props.employeeFamily.hasNotApprovedChanges &&                 
                    <Row>
                        <div className="alert alert-warning" role="alert" style={{width:"100%"}}>
                            Er zijn nog niet bevestigde wijzigingen.
                        </div>
                    </Row>
                }
                <Form>
                    <h5 className="mb-3"><b>Persoonlijke gegevens</b></h5>
                    <Row className="pl-2 pr-2 mb-1">
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Burgerlijke staat</Label>
                            <select {...register("employeeFamily.familyStatus")} className="inputs-fixed-height form-control form-control-md p-0 pl-2">
                                <option value={FamilyStatusType.Gehuwd}>{getFamilyStatusTypeString(FamilyStatusType.Gehuwd)}</option>
                                <option value={FamilyStatusType.Ongehuwd}>{getFamilyStatusTypeString(FamilyStatusType.Ongehuwd)}</option>
                                <option value={FamilyStatusType.WeduweOfWeduwenaar}>{getFamilyStatusTypeString(FamilyStatusType.WeduweOfWeduwenaar)}</option>
                                <option value={FamilyStatusType.WettelijkGescheiden}>{getFamilyStatusTypeString(FamilyStatusType.WettelijkGescheiden)}</option>
                                <option value={FamilyStatusType.WettelijkSamenwonend}>{getFamilyStatusTypeString(FamilyStatusType.WettelijkSamenwonend)}</option>
                                <option value={FamilyStatusType.FeitelijkOfVanTafelEnBedGescheiden}>{getFamilyStatusTypeString(FamilyStatusType.FeitelijkOfVanTafelEnBedGescheiden)}</option>
                                <option value={FamilyStatusType.Samenwonend}>{getFamilyStatusTypeString(FamilyStatusType.Samenwonend)}</option>
                            </select>
                            
                        </Col>
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                                <Label>Burgerlijke staat vanaf</Label>
                                <input type="date" value={getMaritalStateInputDateFormat()} onChange={handleChangeMaritalStateDate} className="inputs-fixed-height form-control"/>
                        </Col>
                    </Row>
                    <hr color="#007bff"></hr>

                    <h5 className="mb-3"><b>Partner</b></h5>
                    <Row className="pl-2 pr-2 mb-1">
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Naam</Label>
                            <input type="text" {...register("employeeFamily.partnerName")} className="inputs-fixed-height form-control"/>
                        </Col>
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Voornaam</Label>
                            <input type="text" {...register("employeeFamily.partnerFirstName")} className="inputs-fixed-height form-control"/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-1">
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Geboortedatum</Label>
                            <input type="date" value={getPartnerBirthDateInputDateFormat()} onChange={handleChangePartnerBirthDate} className="inputs-fixed-height form-control"/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-1">
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Beroepsinkomen</Label>
                            <select {...register("employeeFamily.partnerIncome")} className="inputs-fixed-height form-control form-control-md p-0 pl-2">
                                <option value="true">Ja</option>
                                <option value="false">Nee</option>
                            </select>
                        </Col>
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Partner statuut</Label>
                            <select {...register("employeeFamily.partnerStatute")} className="inputs-fixed-height form-control form-control-md p-0 pl-2">
                                <option value={PartnerStatuteType.Bediende}>{getPartnerStatuteTypeString(PartnerStatuteType.Bediende)}</option>
                                <option value={PartnerStatuteType.Arbeider}>{getPartnerStatuteTypeString(PartnerStatuteType.Arbeider)}</option>
                                <option value={PartnerStatuteType.VasteBeamteOfStagiair}>{getPartnerStatuteTypeString(PartnerStatuteType.VasteBeamteOfStagiair)}</option>
                                <option value={PartnerStatuteType.Dienstbode}>{getPartnerStatuteTypeString(PartnerStatuteType.Dienstbode)}</option>
                                <option value={PartnerStatuteType.Zelfstandige}>{getPartnerStatuteTypeString(PartnerStatuteType.Zelfstandige)}</option>
                                <option value={PartnerStatuteType.Andere}>{getPartnerStatuteTypeString(PartnerStatuteType.Andere)}</option>
                            </select>
                        </Col>
                    </Row>
                    <hr color="#007bff"></hr>

                    <h5 className="mb-3"><b>Personen fiscaal ten laste</b></h5>
                    <Row className="pl-2 pr-2 mb-1">
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Aantal kinderen</Label>
                            {errors.employeeFamily?.amountOfChildren && (
                                <Label style={{color: "red"}}>Tussen 0 en 10</Label>
                            )}
                            <div> 
                                <input type="number" {...register("employeeFamily.amountOfChildren", { validate: (value) => value >= 0 && value <= 10 })} className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Kinderen invalide</Label>
                            {errors.employeeFamily?.amountOfChildrenDisabled && (
                                <Label style={{color: "red"}}>Tussen 0 en 10</Label>
                            )}
                            <div> 
                                <input type="number" {...register("employeeFamily.amountOfChildrenDisabled", { validate: (value) => value >= 0 && value <= 10 })} className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-1">
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Aantal ouderen (&gt;=65j)</Label>
                            {errors.employeeFamily?.amountOfSeniors && (
                                <Label style={{color: "red"}}>Tussen 0 en 10</Label>
                            )}
                            <div> 
                                <input type="number" {...register("employeeFamily.amountOfSeniors", { validate: (value) => value >= 0 && value <= 10 })} className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Ouderen invalide</Label>
                            {errors.employeeFamily?.amountOfSeniorsDisabled && (
                                <Label style={{color: "red"}}>Tussen 0 en 10</Label>
                            )}
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                <input type="number" {...register("employeeFamily.amountOfSeniorsDisabled", { validate: (value) => value >= 0 && value <= 10 })} className="inputs-fixed-height form-control"/>
                            </div>
                        </Col>
                    </Row>
                    <hr color="#007bff"></hr>

                    <Row className="d-flex align-items-center">
                        <Col>
                            <h5 className="mb-3"><b>Kinderen</b></h5>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button color="success" className="mb-3 mr-1 p-2 pl-3 pr-3 d-flex justify-content-center" onClick={openNewChildPopup}>
                                <i className="fas fa-plus fa-md"></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className="pl-3 pr-2 mb-1">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="pl-1 pr-1 text-center">Naam</th>
                                    <th className="pl-1 pr-1 text-center">Voornaam</th>
                                    <th className="pl-1 pr-1 text-center">Geboortedatum</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {children.map((child, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="pl-0 pr-0 text-center" onClick={() => openEditChildPopup(child, index)}>
                                                <Label>{child.lastName}</Label>
                                            </td>
                                            <td className="pl-1 pr-0 text-center" onClick={() => openEditChildPopup(child, index)}>
                                                <Label>{child.firstName}</Label>
                                            </td>
                                            <td className="pl-1 pr-1 text-center" onClick={() => openEditChildPopup(child, index)}>
                                                <Label>{getInputDateFormatString(child.birthDate)}</Label>
                                            </td>
                                            <td style={{width: "20px", paddingLeft: 0, paddingRight: "7px"}}>
                                                <Button 
                                                    color="danger" 
                                                    className="d-flex justify-content-center align-items-center"
                                                    style={{height: "35px"}} 
                                                    onClick={() => removeChildByIndex(index)}
                                                >
                                                    <i className="fas fa-minus fa-md"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Row>
                </Form>
            </div>
        );
    }
    
    return (
        <>
            {getButtonDiv()}
            {getLoadSpinner()}

            <div style={{height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}>
                {getPersonalForm()}
            </div>
        </>
    );
};

export default UserFamilyForm;