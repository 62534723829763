import { Button, Col, Row, Form, Label, ButtonGroup } from 'reactstrap';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import "../GlobalStyles.css";
import FuelCardAllocation from '../../Models/FuelCardAllocation';
import useWindowHeight from '../../Hooks/useWindowHeight';
 
interface Props {
    fuelCardAllocation: FuelCardAllocation;
    props: any;
}

const UserFuelCardForm = (props: Props) => {
    const { windowHeight } = useWindowHeight();
    const history = useHistory();
    const { register } = useForm({
        defaultValues: {
            fuelCardAllocation: props.fuelCardAllocation,
        }
    });

    function getDateInputFormat(dateString: Date): string {
        if (dateString !== null) {
            const date: Date = new Date(dateString);
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');
            const dateStringFormat: string = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
            return dateStringFormat;
        }
        return "";
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{backgroundColor: "#2F5FA1"}} onClick={() => history.push("/overview-user")}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>  
                    <Button className="p-3 buttons-footer" style={{backgroundColor: "#2F5FA1"}} onClick={() => history.push("/overview")}>
                        <i className="fas fa-home fa-2x"></i>
                    </Button>                               
                </ButtonGroup>
            </>
        );
    }

    function getPersonalForm() {
        return (
            <div className="p-0 m-0 mt-3">
                <Form>
                    <h5 className="mb-3"><b>Algemeen</b></h5>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Tankkaart nr.:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("fuelCardAllocation.fuelCard.fuelCardNumber")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Begindatum:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="date" value={getDateInputFormat(props.fuelCardAllocation.startDate)} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Einddatum:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="date" value={getDateInputFormat(props.fuelCardAllocation.endDate)} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Leverancier:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("fuelCardAllocation.fuelCard.supplier")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Opmerking:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("fuelCardAllocation.fuelCard.remark")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-3 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Code:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("fuelCardAllocation.fuelCard.code")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
    
    return (
        <>
            {getButtonDiv()}

            <div style={{height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}>
                {getPersonalForm()}
            </div>
        </>
    );
};

export default UserFuelCardForm;