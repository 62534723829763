import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";
import FuelCardAllocation from "../Models/FuelCardAllocation";

export default class FuelCardService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getFuelCardByEmployeeContactId(employeeContactId: string): Promise<FuelCardAllocation> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetFuelCardByContactId`;
        let fuelCardAllocation: FuelCardAllocation = null as any;
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,

            }),
            body: JSON.stringify(employeeContactId)
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    fuelCardAllocation = mapJsonObjectToFuelCardAllocation(data);
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return fuelCardAllocation;
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}

function mapJsonObjectToFuelCardAllocation(jsonObject: any): FuelCardAllocation {
    return {
        fuelCardAllocationId: jsonObject.FuelCardAllocationId,
        startDate: jsonObject.StartDate,
        endDate: jsonObject.EndDate,
        fuelCard: {
            fuelCardId: jsonObject.FuelCard?.FuelCardId,
            supplier: jsonObject.FuelCard?.Supplier,
            remark: jsonObject.FuelCard?.Remark,
            fuelCardNumber: jsonObject.FuelCard?.FuelCardNumber,
            code: jsonObject.FuelCard?.Code
        },
    }
}
