import City from "./City";
import Nationality from "./Nationality";
import Country from "./Country";
import Division from "./Division";
import Partnership from "./Partnership";
import Department from "./Department";
import EmployeeFunction from "./EmployeeFunction";

export default interface Employee {
    contactId: string;
    ownerId: string;
    initialStartDate: Date;
    statute: StatuteType;
    employeeNumber: string;
    userName: string;
    internalEmailAddress: string;
    fullName: string;
    firstName: string;
    lastName: string;
    age: number;
    dateOfBirth: Date;
    function: EmployeeFunction;
    street: string;
    houseNumber: string;
    postBox: string;
    language: EmployeeLanguageType;
    gender: EmployeeGenderType;
    bankAccount: string;
    city: City;
    birthPlace: City;
    nationality: Nationality;
    country: Country;
    division: Division;
    partnership: Partnership;
    department: Department;

    fixedTelephone: string;
    mobilePhone: string;
    privateEmailAddress: string;
    
    travelType: EmployeeTravelType;
    kmWorkHome: string;

    emergencyContact1: string;
    emergencyContactLink1: string;
    emergencyContactNumber1: string;
    emergencyContact2: string;
    emergencyContactLink2: string;
    emergencyContactNumber2: string;
    emergencyContact3: string;
    emergencyContactLink3: string;
    emergencyContactNumber3: string;

    hasNotApprovedChanges: Boolean;

}

export enum EmployeeLanguageType {
    Nederlands = 220950000,
    Engels = 220950003,
    Frans = 220950001,
    Duits = 220950002
}  

export enum EmployeeGenderType {
    Man = 1,
    Vrouw = 2
}

export enum EmployeeTravelType
{
    EigenVervoer = 220950000,
    Trein = 220950001,
    Tram = 220950002,
    Bus = 220950003,
    Fiets = 220950004,
    GeorganiseerdDoorDeWerkgever = 220950005,
}

export enum StatuteType
{
    Bediende = 220950000,
    Arbeider = 220950001,
    StudentBediende = 220950002,
    StudentArbeider = 220950003,
    Stagiair = 220950006,
    IBO = 220950004,
    Zelfstandige = 220950005,
}

export function getStatuteTypeString(statuteType: StatuteType): string {
    switch (statuteType) {
        case StatuteType.StudentArbeider:
            return "Student arbeider";
        case StatuteType.StudentBediende:
            return "Student bediende";
        default:
            return StatuteType[statuteType];
    }
}