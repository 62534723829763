import { Button, Col, Row, Label, Modal, ModalHeader, ModalBody, ButtonToolbar } from 'reactstrap';
import '../ReactSearchSelect.css';
import "../GlobalStyles.css";
import Driver from '../../Models/Driver';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import MileAgeResponse from '../../Models/Responses/MileAgeResponse';
import DriverService from '../../Services/DriverService';
 
interface Props {
    driver: Driver;
    driverService: DriverService;
    isMileAgePopupOpen: boolean;
    setIsMileAgePopupOpen: Function;
    getCompanyCar: Function;
    setIsPageLoading: Function;
}

const MileAgePopup = (props: Props) => {
    const [dateMileAge, setDateMileAge] = useState<Date>(new Date());
    const [mileAgeNumber, setMileAgeNumber] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (props.isMileAgePopupOpen) {
            setDateMileAge(new Date());
            setMileAgeNumber("");
            setIsLoading(false);
        }
        
    }, [props.isMileAgePopupOpen]);

    function getDateInputFormat(newDate: Date): string {
        const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');
        const date: Date = new Date(newDate);
        const dateStringFormat: string = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
        return dateStringFormat;
    }
    
    function saveMileAge() {
        let errors = false;
        const mileAge = Number(mileAgeNumber);
        
        if (!mileAge || isNaN(mileAge))
            errors = true;

        if (!dateMileAge || dateMileAge.toString().toLowerCase() === "invalid date")
            errors = true;

        if (errors) {
            toast.error("Ongeldig datum of kilometerstand ingevuld.");
            return;
        }

        const mileAgeToSave: MileAgeResponse = {
            companyCarId: props.driver.companyCar.companyCarId,
            date: dateMileAge,
            mileAgeNumber: mileAge
        }

        setIsLoading(true);
        props.setIsMileAgePopupOpen(false);
        props.setIsPageLoading(true);

        props.driverService.addMileAge(mileAgeToSave).then((isAdded) => {
            if (isAdded) {
                toast.success("Kilometerstand is succesvol toegevoegd.");
                props.getCompanyCar();
            } else {
                toast.error("Er is iets fout gegaan tijdens het opslaan van de kilometerstand.");
            }
        }).finally(() => {
            setIsLoading(false);
            props.setIsPageLoading(false);
        });
    }

    function getMileAgePopup() {
        return (
            <Modal isOpen={props.isMileAgePopupOpen} toggle={() => props.setIsMileAgePopupOpen(false)} centered>
                <ModalHeader className="d-flex justify-content-center">Kilometerstand toevoegen</ModalHeader>
                <ModalBody className="p-2">
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Datum:<span style={{color: "red"}}>*</span></Label>
                        </Col>
                        <Col xs="12">
                            <input type="date" value={getDateInputFormat(dateMileAge)} 
                                onChange={(event) => setDateMileAge(new Date(event.target.value))}
                                className="inputs-fixed-height form-control"
                            />
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-3 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Kilometerstand:<span style={{color: "red"}}>*</span></Label>
                        </Col>
                        <Col xs="12">
                            <input type="number" value={mileAgeNumber} onChange={(event) => setMileAgeNumber(event.target.value)} 
                                className="inputs-fixed-height form-control"/>
                        </Col>
                    </Row>
                    <ButtonToolbar className="navbar p-0">
                        <Button color="primary" style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%" }} 
                            onClick={() => props.setIsMileAgePopupOpen(false)} disabled={isLoading}
                        >
                            Annuleer
                        </Button>
                        <Button color="primary" style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%" }}
                            onClick={() => saveMileAge()} disabled={isLoading}
                        >
                            Ja
                        </Button>
                    </ButtonToolbar>
                </ModalBody>
            </Modal>
        );
    }

    return (
        <>
            {getMileAgePopup()}
        </>
    );
};

export default MileAgePopup;