import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import EmployeeService from "../../Services/EmployeeService";
import "../GlobalStyles.css";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import UserHeaderComponent from "../UserHeaderComponent";
import { Button, ButtonGroup } from "reactstrap";
import Employee from "../../Models/Employee";
import { useMsal } from "@azure/msal-react";
import GraphService from "../../Services/GraphService";
import { User } from '@microsoft/microsoft-graph-types';
import useWindowHeight from "../../Hooks/useWindowHeight";

export default function DocumentsClbDivisionPage() {
    const { windowHeight } = useWindowHeight();
    const { instance } = useMsal();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const history = useHistory();
    const [employeeFunction, setEmployeeFunction] = useState<string>();
    const [employee, setEmployee] = useState<Employee>();
    const [user, setUser] = useState<User>();

    useEffect(() => {
        getUser();
        getEmployee();
    }, []);

    async function getUser() {
        let user = await graphService.getUserDetails();
        setUser(user);
    }

    async function getEmployee() {
        let user = await graphService.getUserDetails();
        let userName = user?.userPrincipalName as string;
        if (userName === null) {
            toast.error(`Kan geen gebruiker vinden.`);
            goBackToOverViewApp();
        }

        employeeService.getEmployeeByUserName().then((data: Employee) => {
            if (!data) {
                toast.error(`Kan geen gebruiker met de gebruikersnaam ${userName} vinden.`);
                goBackToOverViewApp();
            } else {
                setEmployeeFunction(data.function?.name);
                setEmployee(data);
            }
            return data;
        });
    }

    function goBackToOverViewApp(): void {
        history.push("/overview");
    }

    function getLoadSpinner() {
        return (
            <MoonLoader
                color="black"
                loading={!employeeFunction}
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    top: 25px`
                }
                size={25} />
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/overview-document")}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={goBackToOverViewApp}>
                        <i className="fas fa-home fa-2x"></i>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    function getPersonalDocumentsDiv() {
        return (
            <div className="p-0 pt-3 m-0" style={{ height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}>
                <div className="pl-3">

                </div>
            </div>
        );
    }

    return (
        <>
            {getLoadSpinner()}
            {employeeFunction && employee && user?.displayName ?
                <>
                    <UserHeaderComponent headerText={`Documenten - ${employee?.partnership?.name}`} />
                    {getPersonalDocumentsDiv()}
                    {getButtonDiv()}
                </>
                : ""}
        </>
    );
}