import EmployeeFunction from "./EmployeeFunction";

export default interface Contract {
    contractId: string;
    contactId: string;
    primaryContractId: string;

    type: ContractType;
    startDate: Date;
    from: Date;
    until: Date;
    paritairComite: string;
    jobtime: string;
    replacementOf: string;
    function: EmployeeFunction;
    functionDescription: string;
    subContracts: Contract[];
}

export enum ContractType {
    Onbekend = 220950000,
    OnbepaaldeDuur = 220950001,
    BepaaldeDuur = 220950002,
    Vervangingscontract = 220950003,
    Opzegbrief = 220950004,
    Bijvoegsel = 220950005,
    BeëindigingWederzijdsAkkoord = 220950006,
    Stagecontract = 220950007,
    Jobstudent = 220950008,
    AanvullendeOvereenkomst = 220950009,
}

export function getContractTypeString(contractType: ContractType): string {
    switch (contractType) {
        case ContractType.AanvullendeOvereenkomst:
            return "Aanvullende Overeenkomst";
        case ContractType.BepaaldeDuur:
            return "Bepaalde duur";
        case ContractType.BeëindigingWederzijdsAkkoord:
            return "Beëindiging wederzijds akkoord";
        case ContractType.OnbepaaldeDuur:
            return "Onbepaalde duur";
        default:
            return ContractType[contractType];
    }
}