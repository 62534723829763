import { Button, ButtonGroup, Col, Row } from "reactstrap";
import "./OverviewUserPage.css";
import "../GlobalStyles.css";
import 'bootstrap/dist/css/bootstrap.css';
import { useHistory } from "react-router";
import UserHeaderComponent from "../UserHeaderComponent";
import useWindowHeight from "../../Hooks/useWindowHeight";

export default function OverviewUserPage() {
    const { windowHeight } = useWindowHeight();
    const history = useHistory();

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/overview")}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    function getContent() {
        return (
            <div style={{ height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}>
                <Row className="mx-auto p-3 mb-3 mt-3 button-row background-color-blue" onClick={() => history.push("/user-general-info")}>
                    <Col xs="10" className="mx-auto d-flex align-items-center">
                        <h5 className="m-0">Algemene info</h5>
                    </Col>
                    <Col xs="2" className="d-flex justify-content-center">
                        <i className="fas fa-info-circle fa-2x"></i>
                    </Col>
                </Row>
                <Row className="mx-auto p-3 mb-3 button-row background-color-blue" onClick={() => history.push("/user-family")}>
                    <Col xs="10" className="mx-auto d-flex align-items-center">
                        <h5 className="m-0">Gezin</h5>
                    </Col>
                    <Col xs="2" className="d-flex justify-content-center">
                        <i className="fas fa-users fa-2x"></i>
                    </Col>
                </Row>
                <Row className="mx-auto p-3 mb-3 button-row background-color-blue" onClick={() => history.push("/user-contracts")}>
                    <Col xs="10" className="mx-auto d-flex align-items-center">
                        <h5 className="m-0">Contract gegevens</h5>
                    </Col>
                    <Col xs="2" className="d-flex justify-content-center">
                        <i className="fas fa-file-alt fa-2x"></i>
                    </Col>
                </Row>
                <Row className="mx-auto p-3 mb-3 button-row background-color-blue" onClick={() => history.push("/user-companycar")}>
                    <Col xs="10" className="mx-auto d-flex align-items-center">
                        <h5 className="m-0">Bedrijfswagen</h5>
                    </Col>
                    <Col xs="2" className="d-flex justify-content-center">
                        <i className="fas fa-car fa-2x"></i>
                    </Col>
                </Row>
                <Row className="mx-auto p-3 mb-3 button-row background-color-blue" onClick={() => history.push("/user-fuelcard")}>
                    <Col xs="10" className="mx-auto d-flex align-items-center">
                        <h5 className="m-0">Tankkaart</h5>
                    </Col>
                    <Col xs="2" className="d-flex justify-content-center">
                        <i className="far fa-credit-card fa-2x"></i>
                    </Col>
                </Row>
                <Row className="mx-auto p-3 mb-3 button-row background-color-blue" onClick={() => history.push("/user-assets-overview")}>
                    <Col xs="10" className="mx-auto d-flex align-items-center">
                        <h5 className="m-0">Werkmiddelen</h5>
                    </Col>
                    <Col xs="2" className="d-flex justify-content-center">
                        <i className="fas fa-laptop fa-2x"></i>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <>
            <UserHeaderComponent headerText={"Persoonlijke gegevens"} />
            
            {getContent()}
            {getButtonDiv()}
        </>
    );
}