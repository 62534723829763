import { Col, Row } from "reactstrap";

interface Props {
    description: string;
    onClickFunction: Function;
    isDisabled: boolean;
    icon: any;
}

const SettingsItem = (props: Props) => {

    return (
        <Row 
            className="mx-auto p-3 mb-3 mt-3 button-row background-color-blue" 
            onClick={() => props.onClickFunction()}
            style={{opacity: props.isDisabled ? 0.7 : 1}}
        >
            <Col xs="10" className="mx-auto d-flex align-items-center">
                <h5 className="m-0">{props.description}</h5>
            </Col>
            <Col xs="2" className="d-flex justify-content-center">
                {props.icon}
            </Col>
        </Row>
    )
}

export default SettingsItem;