import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Button, ButtonGroup, Col, Label, Row, Table } from "reactstrap";
import "../GlobalStyles.css";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import UserHeaderComponent from "../UserHeaderComponent";
import Contract, { ContractType, getContractTypeString } from "../../Models/Contract";
import { useMsal } from "@azure/msal-react";
import GraphService from '../../Services/GraphService';
import TimeTableComponent from "./TimeTableComponent";
import TimeTableService from "../../Services/TimeTableService";
import StaffSchedule from "../../Models/StaffSchedule";
import useWindowHeight from "../../Hooks/useWindowHeight";

export default function UserContractDetailpage() {
    const { windowHeight } = useWindowHeight();
    const { instance } = useMsal();
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const timeTableService = useMemo(() => new TimeTableService(instance), [instance]);
    const history = useHistory();
    const location = useLocation();
    const [contract, setContract] = useState<Contract>();
    const [subContracts, setSubContracts] = useState<Contract[]>();
    const [staffSchedules, setStaffSchedules] = useState<StaffSchedule[]>();

    useEffect(() => {
        getContractDetailsAndStaffSchedules();
    }, []);

    async function getContractDetailsAndStaffSchedules() {
        let user = await graphService.getUserDetails();
        let userName = user?.userPrincipalName as string;
        if (userName === null) {
            toast.error(`Kan geen gebruiker vinden.`);
            goBackToOverViewApp();
        }

        if (!location.state) {
            toast.error("Contract niet gevonden.");
            goBackToContractInfoPage();
        }

        const states = location.state as any;
        const contract = states.contract as Contract;
        setContract(contract);
        setSubContracts(contract.subContracts);

        timeTableService.getStaffSchedulesByContractId(contract.contractId).then((staffSchedules: StaffSchedule[]) => {
            setStaffSchedules(staffSchedules);
        });
    }

    function getInputDateFormat(startDate: Date): string {
        if (startDate !== null) {
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
            const date: Date = new Date(startDate);
            const dateStringFormat: string = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
            return dateStringFormat;
        }
        return "";
    }

    function getDateInStringFormat(startDate: Date): string {
        if (startDate !== null) {
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
            const date: Date = new Date(startDate);
            const dateStringFormat: string = `${zeroPad(date.getDate(), 2)}-${zeroPad(date.getMonth() + 1, 2)}-${date.getFullYear()}`;
            return dateStringFormat;
        }
        return "";
    }

    function goBackToOverViewApp(): void {
        history.push("/overview");
    }

    function goBackToContractInfoPage(): void {
        history.push("/user-contracts");
    }

    async function goToSubContractDetailPage(subContract: Contract): Promise<void> {
        history.push({
            pathname: '/user-subcontract-detail',
            state: { subContract: subContract, contract: contract }
        });
    }

    function noSubContractsFoundDiv() {
        return (
            <div className="pl-3">
                <Label>Geen subcontracten gevonden.</Label>
            </div>
        );
    }

    function getLoadSpinner() {
        return (
            <MoonLoader
                color="black"
                loading={!contract || !staffSchedules || !subContracts}
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    top: 125px`
                }
                size={25} />
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/user-contracts")}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/overview")}>
                        <i className="fas fa-home fa-2x"></i>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    function getGeneralDiv() {
        return (
            <div className="p-0 m-0 mt-3">
                <h5 className="mb-3"><b>Algemeen</b></h5>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Aantal uren per week:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" value={contract?.jobtime} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Contracttype:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" value={getContractTypeString(contract?.type as ContractType)} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Van:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="date" value={getInputDateFormat(contract?.from as Date)} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Tot en met:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="date" value={getInputDateFormat(contract?.until as Date)} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Functie:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" value={contract?.function?.name} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Functieomschrijving:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" value={contract?.functionDescription} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Paritair Comité:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" value={contract?.paritairComite} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Vervanging van:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" value={contract?.replacementOf} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <hr color="#007bff"></hr>
            </div>
        );
    }

    function getSubcontractsOverviewDiv() {
        return (
            <div className="p-0 m-0 mt-3">
                <h5 className="mb-3"><b>Subcontracten</b></h5>
                {subContracts && subContracts.length > 0 ?
                    <div>
                        <Table style={{ tableLayout: "fixed" }}>
                            <thead>
                                <tr>
                                    <th className="text-center">Type</th>
                                    <th className="text-center">Van</th>
                                    <th className="text-center">Tot en met</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subContracts.map((subContract, index) => {
                                    return (
                                        <>
                                            <tr key={index} onClick={() => goToSubContractDetailPage(subContract)}>
                                                <td className="text-center p-1 align-middle">
                                                    <Label>{getContractTypeString(subContract.type)}</Label>
                                                </td>
                                                <td className="text-center p-1 pl-2 align-middle">
                                                    <Label>{getDateInStringFormat(subContract.startDate)}</Label>
                                                </td>
                                                <td className="text-center p-1 pl-2 align-middle">
                                                    <Label>{getDateInStringFormat(subContract.until)}</Label>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                    : noSubContractsFoundDiv()}
            </div>
        );
    }

    return (
        <>
            {getLoadSpinner()}
            <UserHeaderComponent headerText={"Contract gegevens"} />

            {contract && staffSchedules && subContracts &&
                <>
                    <div style={{ height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}>
                        {getGeneralDiv()}
                        <TimeTableComponent staffSchedules={staffSchedules} />
                        <hr color="#007bff"></hr>
                        {getSubcontractsOverviewDiv()}
                    </div>

                    {getButtonDiv()}
                </>
            }
        </>
    );
}