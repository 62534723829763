import { useState } from "react";
import "../GlobalStyles.css";
import "./TimeTableComponent.css";
import { Button, ButtonGroup, Col, Label, Row, Table } from "reactstrap";
import StaffSchedule from "../../Models/StaffSchedule";

interface Props {
    staffSchedules: StaffSchedule[];
}

export default function TimeTableComponent(props: Props) {
    const [staffSchedules] = useState<StaffSchedule[]>(props.staffSchedules);
    const [selectedStaffScheduleIndex, setSelectedStaffScheduleIndex] = useState<number>(0);
    const [selectedStaffSchedule, setSelectedStaffSchedule] = useState<StaffSchedule>(props.staffSchedules[0]);
    
    function noTimeTablesFoundDiv() {
        return (
            <div className="pl-3">
                <Label>Geen uurroosters gevonden</Label>
            </div>
        );
    }
    
    function nextStaffSchedule(): void {
        if (selectedStaffScheduleIndex < staffSchedules.length - 1) {
            setSelectedStaffSchedule([...staffSchedules][selectedStaffScheduleIndex + 1])
            setSelectedStaffScheduleIndex(index => index + 1);
        }
    }

    function previousStaffSchedule(): void {
        if (selectedStaffScheduleIndex > 0) {
            setSelectedStaffSchedule([...staffSchedules][selectedStaffScheduleIndex - 1]);
            setSelectedStaffScheduleIndex(index => index - 1);
        }
    }

    function setStaffScheduleFromDropDown(event: any): void {
        const index = Number.parseInt(event.target.value);
        setSelectedStaffSchedule([...staffSchedules][index]);
        setSelectedStaffScheduleIndex(index);
    }

    function getTimeTable() {
        const timeTable = selectedStaffSchedule?.timeTable;

        return (
            <>
                {selectedStaffSchedule &&
                    <Table borderless={true} className="timetable-table">
                        <thead style={{border: "none"}}>
                            <tr>
                                <th style={{width: "80px", textAlign: "center"}}>{selectedStaffSchedule?.weekNumber}</th>
                                <th style={{width: "80px", textAlign: "center"}}>Uren</th>
                                <th style={{width: "110px", textAlign: "center"}}>VM</th>
                                <th style={{width: "110px", textAlign: "center"}}>NM</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="row-border-top">
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>Ma</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.hoursMonday}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.mondayAm}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.mondayPm}</b></Label>
                                </td>
                            </tr>
                            <tr className="row-border-top">
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>Di</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.hoursTuesday}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.tuesdayAm}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.tuesdayPm}</b></Label>
                                </td>
                            </tr>
                            <tr className="row-border-top">
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>Wo</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.hoursWednesday}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.wednesdayAm}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.wednesdayPm}</b></Label>
                                </td>
                            </tr>
                            <tr className="row-border-top">
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>Do</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.hoursThursday}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.thursdayAm}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.thursdayPm}</b></Label>
                                </td>
                            </tr>
                            <tr className="row-border-top">
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>Vr</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.hoursFriday}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.fridayAm}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.fridayPm}</b></Label>
                                </td>
                            </tr>
                            <tr className="row-border-top">
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>Za</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.hoursSaturday}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.saturdayAm}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.saturdayPm}</b></Label>
                                </td>
                            </tr>
                            <tr className="row-border-top">
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>Zo</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.hoursSunday}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.sundayAm}</b></Label>
                                </td>
                                <td className="text-center p-1">
                                    <Label className="label-padding"><b>{timeTable?.sundayPm}</b></Label>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                }
            </>
        );
    }

    return (
        <div className="p-0 m-0 mt-3">
            <Row>
                <Col className="d-flex align-items-center">
                    <h5 className="mb-3"><b>Uurrooster</b></h5>
                </Col>
                <Col className="mb-2 d-flex justify-content-end">
                    <ButtonGroup className="p-0" hidden={staffSchedules.length === 0}>
                        <Button 
                            className="p-2" 
                            style={{backgroundColor: "#2F5FA1", width: "50px"}} 
                            onClick={previousStaffSchedule} disabled={selectedStaffScheduleIndex <= 0}
                        >
                            <i className="fas fa-arrow-left fa-md"></i>
                        </Button>     
                        <select 
                            className="form-control form-control-md p-0 pl-2" 
                            style={{width: "60px", height: "42px", borderRadius: "0"}} 
                            onChange={setStaffScheduleFromDropDown}
                        >
                            {selectedStaffSchedule && staffSchedules.map((staffSchedule, index) => {
                                return (
                                    <option 
                                        key={index} 
                                        selected={selectedStaffSchedule.weekNumber === staffSchedule.weekNumber} 
                                        value={index}>{staffSchedule?.weekNumber}
                                    </option>
                                );
                            })}
                        </select>                 
                        <Button 
                            className="p-2" 
                            style={{backgroundColor: "#2F5FA1", width: "50px"}} 
                            onClick={nextStaffSchedule} 
                            disabled={selectedStaffScheduleIndex >= staffSchedules.length - 1}
                        >
                            <i className="fas fa-arrow-right fa-md"></i>
                        </Button>   
                    </ButtonGroup>
                </Col>
            </Row>

            {staffSchedules.length > 0 ?
                <>
                    {getTimeTable()}
                </>
            : 
                noTimeTablesFoundDiv()
            }
        </div>
    );
}