import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Button, ButtonGroup, Col, Label, Row } from "reactstrap";
import "../GlobalStyles.css";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import UserHeaderComponent from "../UserHeaderComponent";
import Contract, { ContractType } from "../../Models/Contract";
import { useMsal } from "@azure/msal-react";
import GraphService from '../../Services/GraphService';
import StaffSchedule from "../../Models/StaffSchedule";
import TimeTableService from "../../Services/TimeTableService";
import TimeTableComponent from "./TimeTableComponent";
import useWindowHeight from "../../Hooks/useWindowHeight";

export default function UserSubContractDetailpage() {
    const { windowHeight } = useWindowHeight();
    const { instance } = useMsal();
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const history = useHistory();
    const location = useLocation();
    const [subContract, setSubContract] = useState<Contract>();
    const [contract, setContract] = useState<Contract>();
    const [staffSchedules, setStaffSchedules] = useState<StaffSchedule[]>();
    const timeTableService = useMemo(() => new TimeTableService(instance), [instance]);

    useEffect(() => {
        getContractDetails();
    }, []);
    
    async function getContractDetails() {
        let user = await graphService.getUserDetails();
        let userName = user?.userPrincipalName as string;
        if (userName === null) {
            toast.error(`Kan geen gebruiker vinden.`);
            goBackToOverViewApp();
        }

        if (!location.state) {
            toast.error("Subcontract niet gevonden.");
            goBackToContractDetailPage();
        }

        const states = location.state as any;
        const subContract = states.subContract as Contract;
        const contract = states.contract as Contract;
        setSubContract(subContract);
        setContract(contract);

        timeTableService.getStaffSchedulesByContractId(subContract.contractId).then((staffSchedules: StaffSchedule[]) => {
            setStaffSchedules(staffSchedules);
        });
    }

    function getInputDateFormat(startDate: Date): string {
        if (startDate !== null) {
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
            const date: Date = new Date(startDate);
            const dateStringFormat: string = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
            return dateStringFormat;
        }
        return "";
    }

    function goBackToOverViewApp(): void {
        history.push("/overview");
    }

    function goBackToContractDetailPage(): void {
        history.push({
            pathname: '/user-contract-detail',
            state: { contract: contract }
        });
    }

    function getContractTypeString(contractType: ContractType): string {
        switch (contractType) {
            case ContractType.AanvullendeOvereenkomst:
                return "Aanvullende Overeenkomst";
            case ContractType.BepaaldeDuur:
                return "Bepaalde duur";
            case ContractType.BeëindigingWederzijdsAkkoord:
                return "Beëindiging wederzijds akkoord";
            case ContractType.OnbepaaldeDuur:
                return "Onbepaalde duur";
            default:
                return ContractType[contractType];
        }
    }

    function getLoadSpinner() {
        return (
            <MoonLoader
                color="black"
                loading={!subContract}
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    top: 125px`
                }
                size={25} />
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={goBackToContractDetailPage}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/overview")}>
                        <i className="fas fa-home fa-2x"></i>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    function getGeneralDiv() {
        return (
            <div className="p-0 m-0 mt-3">
                <h5 className="mb-3"><b>Algemeen</b></h5>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Aantal uren per week:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" value={subContract?.jobtime} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Contracttype:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" value={getContractTypeString(subContract?.type as ContractType)} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Van:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="date" value={getInputDateFormat(subContract?.startDate as Date)} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Tot en met:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="date" value={getInputDateFormat(subContract?.until as Date)} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Functie:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" value={subContract?.function?.name} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Functieomschrijving:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" value={subContract?.functionDescription} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Paritair Comité:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" value={subContract?.paritairComite} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <Row className="pl-2 pr-2 mb-3 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Vervanging van:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" value={subContract?.replacementOf} className="inputs-fixed-height form-control" readOnly />
                    </Col>
                </Row>
                <hr color="#007bff"></hr>
            </div>
        );
    }

    return (
        <>
            {getLoadSpinner()}
            <UserHeaderComponent headerText={"Contract gegevens - Subcontracten"} />

            {subContract && staffSchedules &&
                <>
                    <div style={{ height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}>
                        {getGeneralDiv()}
                        <TimeTableComponent staffSchedules={staffSchedules} />
                    </div>

                    {getButtonDiv()}
                </>
            }
        </>
    );
}