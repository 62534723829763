import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OverviewAppPage from './Components/OverviewApp/OverviewAppPage';
import OverviewUserPage from './Components/OverviewUser/OverviewUserPage';
import UserGeneralInfoPage from './Components/UserGeneralInfo/UserGeneralInfoPage';
import UserFamilyPage from './Components/UserFamily/UserFamilyPage';
import UserFuelCardPage from './Components/UserFuelCard/UserFuelCardPage';
import UserCompanyCarPage from './Components/UserCompanyCar/UserCompanyCarPage';
import UserAssetOverviewPage from './Components/UserAssets/UserAssetOverviewPage';
import UserAssetDetailPage from './Components/UserAssets/UserAssetDetailPage';
import UserContractInfoPage from './Components/UserContracts/UserContractInfoPage';
import UserContractDetailpage from './Components/UserContracts/UserContractDetailpage';
import UserSubContractDetailpage from './Components/UserContracts/UserSubContractDetailpage';
import OverviewDocumentPage from './Components/OverviewDocument/OverviewDocumentPage';
import DocumentsPersonalPage from './Components/DocumentsPersonal/DocumentsPersonalPage';
import DocumentsClbGroupPage from './Components/DocumentsClbGroup/DocumentsClbGroupPage';
import DocumentsClbDivisionPage from './Components/DocumentsClbDivision/DocumentsClbDivisionPage';
import '@fortawesome/fontawesome-free/css/all.css';
import { AuthenticationResult, EventMessage, EventType, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest } from './Config';
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import DocumentsUploadedPage from './Components/DocumentsUploaded/DocumentsUploadedPage';
import { useEffect } from 'react';
import { initPushNotifications } from './Helpers/OneSignalNotificationsHelper';
import Settings from './Components/Settings/Settings';
import ManualAddAppToHomeScreen from './Components/Settings/ManualAddAppToHomeScreen/ManualAddAppToHomeScreen';

const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const App = () => {
    useEffect(() => {
        initPushNotifications();
    }, []);
    
    return (
        <MsalProvider instance={msalInstance}>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover 
            />

            <Router>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
                    <main role="main" className="container-fluid">
                        <Route exact path="/overview">
                            <OverviewAppPage />
                        </Route>
                        <Route exact path="/overview-user">
                            <OverviewUserPage />
                        </Route>
                        <Route exact path="/user-general-info">
                            <UserGeneralInfoPage />
                        </Route>
                        <Route exact path="/user-family">
                            <UserFamilyPage />
                        </Route>
                        <Route exact path="/user-fuelcard">
                            <UserFuelCardPage />
                        </Route>
                        <Route exact path="/user-companycar">
                            <UserCompanyCarPage />
                        </Route>
                        <Route exact path="/user-assets-overview">
                            <UserAssetOverviewPage />
                        </Route>
                        <Route exact path="/user-assets-detail">
                            <UserAssetDetailPage />
                        </Route>
                        <Route exact path="/user-contracts">
                            <UserContractInfoPage />
                        </Route>
                        <Route exact path="/user-contract-detail">
                            <UserContractDetailpage />
                        </Route>
                        <Route exact path="/user-subcontract-detail">
                            <UserSubContractDetailpage />
                        </Route>
                        <Route exact path="/overview-document">
                            <OverviewDocumentPage />
                        </Route>
                        <Route exact path="/documents-personal">
                            <DocumentsPersonalPage />
                        </Route>
                        <Route exact path="/documents-clb-division">
                            <DocumentsClbDivisionPage />
                        </Route>
                        <Route exact path="/documents-clb-group">
                            <DocumentsClbGroupPage />
                        </Route>
                        <Route exact path="/documents-uploaded">
                            <DocumentsUploadedPage />
                        </Route>

                        {/* Settings */}
                        <Route exact path="/Settings">
                            <Settings />
                        </Route>
                        <Route exact path="/ManualAddAppToHomeScreen">
                            <ManualAddAppToHomeScreen />
                        </Route>

                        <Route exact path="/">
                            <OverviewAppPage />
                        </Route>
                    </main>
                </MsalAuthenticationTemplate>
            </Router>
        </MsalProvider>
    );
}

export default App;