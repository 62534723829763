const EMPLOYEE_FUNCTION = "EMPLOYEE_FUNCTION";
const EMPLOYEE_USERNAME = "EMPLOYEE_USERNAME";

export function setEmployeeFunction(employeeFunction: string) {
    localStorage.setItem(EMPLOYEE_FUNCTION, employeeFunction)
}

export function getEmployeeFunction() {
    return localStorage.getItem(EMPLOYEE_FUNCTION);
}

export function setEmployeeUserName(userName: string) {
    localStorage.setItem(EMPLOYEE_USERNAME, userName)
}

export function getEmployeeUserName() {
    return localStorage.getItem(EMPLOYEE_USERNAME);
}