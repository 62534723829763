import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import EmployeeService from "../../Services/EmployeeService";
import DocumentService from "../../Services/DocumentService";
import "../GlobalStyles.css";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import UserHeaderComponent from "../UserHeaderComponent";
import { Button, ButtonGroup, ButtonToolbar, Col, Form, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Employee from "../../Models/Employee";
import { useMsal } from "@azure/msal-react";
import GraphService from '../../Services/GraphService';
import DocumentFile, { translateTypeAbsenceCertificate, TypeAbsenceCertificate, TypeDocument } from "../../Models/DocumentFile";
import useWindowHeight from "../../Hooks/useWindowHeight";
import UploadFileIcon from '@mui/icons-material/UploadFile';

export default function DocumentsUploadedPage() {
    const { windowHeight } = useWindowHeight();
    const { instance } = useMsal();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const documentService = useMemo(() => new DocumentService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const history = useHistory();
    const [, setEmployee] = useState<Employee>();
    const [documentFiles, setDocumentFiles] = useState<DocumentFile[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showUploadNewDocumentPopup, setShowUploadNewDocumentPopup] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState<string>();
    const [newDocumentFile, setNewDocumentFile] = useState<DocumentFile>(
        {fileName: "", fileBytes: "", contentType: "", typeDocument: null as any, typeDocumentName: null as any, 
        typeAbsenceCertificate: TypeAbsenceCertificate.Ziekte, createdDate: new Date(), fileExtension: "", fileNameWithPrefix: "", isInUploadFolder: true}
    );

    useEffect(() => {
        getEmployee();
    }, []);

    async function getEmployee() {
        let user = await graphService.getUserDetails();
        let userName = user?.userPrincipalName as string;
        if (userName === null) {
            toast.error(`Kan geen gebruiker vinden.`);
            goBackToOverViewApp();
        }

        employeeService.getEmployeeByUserName().then((data: Employee) => {
            if (!data) {
                toast.error(`Kan geen gebruiker met de gebruikersnaam ${userName} vinden.`);
                goBackToOverViewApp();
            } else {
                setEmployee(data);
            }
            return data;
        });

        getUploadedDocuments();
    }

    function getUploadedDocuments(): void {
        documentService.getUploadedDocumentsByName().then((documentFiles: DocumentFile[]) => {
            setDocumentFiles(documentFiles);
        })
    }

    function getInputDateFormatString(startDate: Date): string {
        if (startDate !== null) {
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
            const date: Date = new Date(startDate);
            const dateStringFormat: string = `${zeroPad(date.getDate(), 2)}-${zeroPad(date.getMonth() + 1, 2)}-${date.getFullYear()}`;
            return dateStringFormat;
        }
        return "";
    }

    async function capturePhoto(target: any) {
        if (target.files && target.files.length !== 0) {
            const splittedName = target.files[0].name.split(".");
            const extension = `.${splittedName[splittedName.length - 1]}`;

            const byteArray = await target.files[0].arrayBuffer() as ArrayBuffer;
            const stringArray = new Uint8Array(byteArray).toString();
            setUploadedFileName(target.files[0].name);

            const updatedNewDocumentUpload = {...newDocumentFile};
            updatedNewDocumentUpload.fileBytes = stringArray;
            updatedNewDocumentUpload.fileExtension = extension; 
            updatedNewDocumentUpload.contentType = target.files[0].type; 
            setNewDocumentFile(updatedNewDocumentUpload);
        }
    }

    function uploadDocument(): void {
        if (newDocumentFile.fileBytes === "") {
            toast.error("Er is nog geen document gekozen om te opladen.");
            return;
        }
        if (newDocumentFile.fileName.trim() === "") {
            toast.error("Gelieve een document naam in te vullen.");
            return;
        }
        if (newDocumentFile.typeDocument != TypeDocument.Afwezigheidsattest && newDocumentFile.typeDocument != TypeDocument.Rijbewijs) {
            toast.error("Gelieve het type van het document te kiezen.");
            return;
        }

        newDocumentFile.fileName = newDocumentFile.fileName.trim().replaceAll("/", "-").replaceAll("\\", "-");
        newDocumentFile.fileNameWithPrefix = newDocumentFile.fileName + newDocumentFile.fileExtension;
        setIsLoading(true);
        setShowUploadNewDocumentPopup(false);

        documentService.uploadDocument(newDocumentFile).then((isCreated: boolean) => {
            if (isCreated) {
                toast.success("Document is succesvol opgeslagen.");
                getUploadedDocuments();
            } else {
                toast.error("Er is iet fout gegaan bij het opslaan van uw document.");
            }
        }).catch(() => {
            toast.error("Er is iet fout gegaan bij het opslaan van uw document.");
        }).finally(() => {
            setIsLoading(false);
        });
    }


    function handleUploadNewDocumentChange(property: string, data: any): void {
        const updatedNewDocumentUpload = {...newDocumentFile};
        (updatedNewDocumentUpload as any)[property] = data;
        setNewDocumentFile(updatedNewDocumentUpload);
    }

    function goBackToOverViewApp(): void {
        history.push("/overview");
    }

    function openNewDocumentPopup(): void {
        setNewDocumentFile(
            {fileName: "", fileBytes: "", contentType: "", typeDocument: null as any, typeDocumentName: null as any, 
            typeAbsenceCertificate: TypeAbsenceCertificate.Ziekte, createdDate: new Date(), fileExtension: "", fileNameWithPrefix: "", isInUploadFolder: true}
        );
        setUploadedFileName("");
        setShowUploadNewDocumentPopup(true);
    }

    function getUploadNewDocumentPopup() {
        return (
            <Modal isOpen={showUploadNewDocumentPopup} toggle={() => setShowUploadNewDocumentPopup(false)} centered>
                <ModalHeader className="d-flex justify-content-center">Document opladen</ModalHeader>
                <ModalBody className="p-2">
                    <Row className="pl-2 pr-2 mb-2">
                        {/* <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end mt-3 mb-3">
                            <label style={{width: "40px", height: "35px",
                                backgroundColor: "white", color: "#2F5FA1", borderRadius: ".25rem"}}
                                className="d-flex align-items-center justify-content-center m-auto">
                                <input
                                    accept="image/*,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx,.xls,.csv"
                                    id="upload-image"
                                    type="file"
                                    onChange={(e) => capturePhoto(e.target)}
                                    style={{display: "none", width: "100%", height: "100%", backgroundColor: "#2F5FA1", color: "white"}}
                                    multiple
                                />
                                <UploadFileIcon style={{fontSize: "55px"}} />
                            </label>
                        </Col> */}
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Document naam: <span style={{color: "red"}}>*</span></Label>
                            <input type="text" value={newDocumentFile.fileName ?? ""} className="inputs-fixed-height form-control" 
                                onChange={(data) => handleUploadNewDocumentChange("fileName", data.target.value)} />
                        </Col>
                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Type document: <span style={{color: "red"}}>*</span></Label>
                            <select value={newDocumentFile.typeDocument} className="inputs-fixed-height form-control form-control-md p-0 pl-2"
                                onChange={(data) => handleUploadNewDocumentChange("typeDocument", data.target.value)}>
                                <option></option>
                                <option value={TypeDocument.Afwezigheidsattest}>{TypeDocument[TypeDocument.Afwezigheidsattest]}</option>
                                <option value={TypeDocument.Rijbewijs}>{TypeDocument[TypeDocument.Rijbewijs]}</option>
                            </select>  
                        </Col>
                        {newDocumentFile.typeDocument == TypeDocument.Afwezigheidsattest ?
                            <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                                <Label>Type afwezigheidsattest: <span style={{color: "red"}}>*</span></Label>
                                <select value={newDocumentFile.typeAbsenceCertificate} className="inputs-fixed-height form-control form-control-md p-0 pl-2"
                                    onChange={(data) => handleUploadNewDocumentChange("typeAbsenceCertificate", data.target.value)}>
                                    <option value={TypeAbsenceCertificate.Ziekte}>{translateTypeAbsenceCertificate(TypeAbsenceCertificate.Ziekte)}</option>
                                    <option value={TypeAbsenceCertificate.KleinVerlet}>{translateTypeAbsenceCertificate(TypeAbsenceCertificate.KleinVerlet)}</option>
                                    <option value={TypeAbsenceCertificate.Opleiding}>{translateTypeAbsenceCertificate(TypeAbsenceCertificate.Opleiding)}</option>
                                    <option value={TypeAbsenceCertificate.FamiliaalVerlof}>{translateTypeAbsenceCertificate(TypeAbsenceCertificate.FamiliaalVerlof)}</option>
                                </select>
                            </Col>
                        : ""}

                        <Col xs="12" className="pl-3 mb-2 d-flex flex-column justify-content-end">
                            <Label>Bestand: <span style={{color: "red"}}>*</span></Label>
                            <InputGroup>
                                <Input disabled={true} value={uploadedFileName ?? ""} className="inputs-fixed-height form-control" />
                                
                                <label 
                                    style={{
                                        width: "40px", height: "35px",
                                        backgroundColor: "white", color: "#2F5FA1", borderRadius: ".25rem"
                                    }}
                                    className="d-flex align-items-center justify-content-center m-auto"
                                >
                                    <input
                                        accept="image/*,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx,.xls,.csv"
                                        id="upload-image"
                                        type="file"
                                        onChange={(e) => capturePhoto(e.target)}
                                        style={{display: "none", width: "100%", height: "100%", backgroundColor: "#2F5FA1", color: "white"}}
                                        multiple
                                    />
                                    <UploadFileIcon style={{fontSize: "43px"}} />
                                </label>
                            </InputGroup>
                        </Col>
                    </Row>
                    <ButtonToolbar className="navbar p-0">
                        <Button color="primary" style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%" }} onClick={() => setShowUploadNewDocumentPopup(false)}>Annuleer</Button>
                        <Button color="primary" style={{ backgroundColor: "#2F5FA1", borderColor: "#1c54a1", width: "49%" }} onClick={uploadDocument}>Opslaan</Button>
                    </ButtonToolbar>
                </ModalBody>
            </Modal>
        );
    }

    function getLoadSpinner() {
        return (
            <MoonLoader
                color="black"
                loading={!documentFiles || isLoading}
                css={
                    css`
                        display: block;
                        position: absolute;
                        left: calc(50% - 18px);
                        margin-top: 125px;
                        z-index: 9`
                }
                size={25} />
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/overview-document")}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={goBackToOverViewApp}>
                        <i className="fas fa-home fa-2x"></i>
                    </Button>
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={openNewDocumentPopup}  disabled={isLoading}>
                        <i className="fas fa-upload fa-2x"></i>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    function getUploadedDocumentsDiv() {
        return (
            documentFiles ?
                <div className="p-0 m-0 mt-3">
                    <Form>
                        <>
                            {documentFiles.length > 0 ?
                                documentFiles.map((document, index) => {
                                    return (
                                        <Row key={index} className="m-0 p-2 mb-2 button-row background-color-blue" style={{width: "100%"}}>
                                            <Col xs="5" className="mx-auto d-flex align-items-center">
                                                <Label className="m-0">{getInputDateFormatString(document.createdDate)}</Label>
                                            </Col>
                                            <Col xs="7" className="d-flex align-items-center">
                                                <Row>
                                                    <Col xs="12">
                                                        <Label className="m-0">{TypeDocument[document.typeDocument]}</Label>
                                                    </Col>
                                                    <Col xs="12">
                                                        <Label className="m-0">{(document.typeDocument !== TypeDocument.Afwezigheidsattest ? "/" : translateTypeAbsenceCertificate(document.typeAbsenceCertificate))}</Label>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    );
                                })
                            : <Label className="pl-3">Geen documenten gevonden</Label>}
                        </>
                    </Form>
                </div>
            : ""
        );
    }

    return (
        <>
            {getLoadSpinner()}
            {getUploadNewDocumentPopup()}
            <UserHeaderComponent headerText={"Documenten opladen"} />

            <div style={{height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}>
                {getUploadedDocumentsDiv()}
            </div>
            {getButtonDiv()}
        </>
    );
}