import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import EmployeeService from "../../Services/EmployeeService";
import DocumentService from "../../Services/DocumentService";
import "../GlobalStyles.css";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import UserHeaderComponent from "../UserHeaderComponent";
import { Button, ButtonGroup, Col, Label, Row } from "reactstrap";
import DocumentFile from "../../Models/DocumentFile";
import DocumentFileResponse from "../../Models/Responses/DocumentFileResponse";
import Employee from "../../Models/Employee";
import { useMsal } from "@azure/msal-react";
import GraphService from '../../Services/GraphService';
import useWindowHeight from "../../Hooks/useWindowHeight";

export default function DocumentsPersonalPage() {
    const { windowHeight } = useWindowHeight();
    const { instance } = useMsal();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const documentService = useMemo(() => new DocumentService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const history = useHistory();
    const [, setEmployee] = useState<Employee>();
    const [documentFiles, setDocumentFiles] = useState<DocumentFile[]>();
    const [documentTypes,setDocumentTypes] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        getEmployee();
    }, []);

    async function getEmployee() {
        let user = await graphService.getUserDetails();
        let userName = user?.userPrincipalName as string;
        if (userName === null) {
            toast.error(`Kan geen gebruiker vinden.`);
            goBackToOverViewApp();
        }

        employeeService.getEmployeeByUserName().then((data: Employee) => {
            if (!data) {
                toast.error(`Kan geen gebruiker met de gebruikersnaam ${userName} vinden.`);
                goBackToOverViewApp();
            } else {
                setEmployee(data);
            }
            return data;
        });

        documentService.getPersonalDocumentsByName().then((documentFiles: DocumentFile[]) => {
            setDocumentFiles(documentFiles);
            console.log(documentFiles);
        })
    }

    async function downloadFile(documentFile: DocumentFile): Promise<void> {
        if (!isLoading) {
            setIsLoading(true);
            const documentFileResponse: DocumentFileResponse = {
                fileName: documentFile.fileName,
                isInUploadFolder: documentFile.isInUploadFolder,
            };

            documentService.getDocumentBase64StringByName(documentFileResponse).then(async (byteArray: string) => {
                const contentType = documentFile.contentType === null ? "application/octet-stream" : documentFile.contentType;
                const linkSource = 'data:' + contentType + ';base64,' + byteArray;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = documentFile.fileNameWithPrefix;
                downloadLink.click();
            }).catch(() => {
                toast.error("Document kan niet gedownload worden.");
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    function goBackToOverViewApp(): void {
        history.push("/overview");
    }

    function getLoadSpinner() {
        return (
            <MoonLoader
                color="black"
                loading={!documentFiles || isLoading}
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    margin-top: 125px;
                    z-index: 9`
                }
                size={25} />
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/overview-document")}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={goBackToOverViewApp}>
                        <i className="fas fa-home fa-2x"></i>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    
    function getSortedUniqueDocumentTypes(documentFiles?: DocumentFile[]){
        documentFiles?.forEach((documentFile: DocumentFile)=> {
            if(!documentTypes.includes(documentFile.typeDocumentName?.toString())){
                setDocumentTypes([...documentTypes,documentFile.typeDocumentName?.toString()]);
            }
        })
        return documentTypes.sort();
    }

    function getPersonalDocumentsDiv() {
        getSortedUniqueDocumentTypes(documentFiles);
        documentFiles?.sort((a, b) => a.typeDocumentName.localeCompare(b.typeDocumentName))
        return (
            documentFiles ?
                <div className="p-0 pt-3 m-0" style={{ height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}>
                    {documentFiles.length > 0 ?
                        documentTypes?.map((type, i) =>{
                            return(
                                <div key={i}>
                                    <h4>{type}</h4>
                                    {documentFiles?.map((documentFile) =>{
                                        if(type == documentFile.typeDocumentName){
                                            return (
                                                <div key={documentFile.fileName}>
                                                    <Row  className="mx-auto p-3 mb-2 button-row background-color-blue" 
                                                    onClick={() => downloadFile(documentFile)}>
                                                        <Col xs="10" className="mx-auto d-flex align-items-center">
                                                            <h5 className="m-0">{documentFile.fileNameWithPrefix}</h5>
                                                        </Col>
                                                        <Col xs="2" className="d-flex align-items-center">
                                                            <i className="fas fa-file-download fa-2x"></i>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            );
                                        }
                                    })}
                                    <hr></hr>
                                </div>                   
                            )
                        })
                    :<Label className="pl-3">Geen documenten gevonden</Label>}
                </div>
                : ""
        );
    }

    return (
        <>
            {getLoadSpinner()}
            <UserHeaderComponent headerText={"Documenten - Persoonlijk"} />

            {getPersonalDocumentsDiv()}
            {getButtonDiv()}
        </>
    );
}