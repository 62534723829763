import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";
import Reminder from "../Models/Reminder";

export default class ReminderService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getAllReminders(): Promise<Reminder[]> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetAllReminders`;
        let reminders: Reminder[] = [];
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'OnPremisesUserPrincipalName': sessionStorage.getItem('onPremisesUserPrincipalName') ?? ""
            }),
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    data.forEach((responseObject: any) => {
                        let weeklyNewsItem: Reminder = mapJsonObjectToReminder(responseObject)
                        reminders.push(weeklyNewsItem);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return reminders;
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}

function mapJsonObjectToReminder(jsonObject: any): Reminder {
    return {
        subject: jsonObject.Subject,
        createdOn: new Date(jsonObject.CreatedOn),
        fromDate: new Date(jsonObject.FromDate)
    };
}
