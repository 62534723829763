import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";
import TimeTable from '../Models/TimeTable';
import StaffSchedule from "../Models/StaffSchedule";

export default class TimeTableService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getStaffSchedulesByContractId(contractId: string): Promise<StaffSchedule[]> {
        const baseUrl = process.env.REACT_APP_API_URI;
        const url = `${baseUrl}/api/GetStaffSchedulesByContractId`;
        const staffSchedules: StaffSchedule[] = [];
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            }),
            body: JSON.stringify(contractId)
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    data.forEach((responseObject: any) => {
                        const staffSchedule: StaffSchedule = mapJsonObjectStaffSchedule(responseObject);
                        staffSchedules.push(staffSchedule);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return staffSchedules;
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}

function mapJsonObjectStaffSchedule(jsonObject: any): StaffSchedule {
    return {
        weekNumber: jsonObject.WeekNumber,
        timeTable: {
            contactId: jsonObject.TimeTable?.ContactId,
            hoursMonday: jsonObject.TimeTable?.HoursMonday,
            mondayAm: jsonObject.TimeTable?.MondayAm,
            mondayPm: jsonObject.TimeTable?.MondayPm,
            hoursTuesday: jsonObject.TimeTable?.HoursTuesday,
            tuesdayAm: jsonObject.TimeTable?.TuesdayAm,
            tuesdayPm: jsonObject.TimeTable?.TuesdayPm,
            hoursWednesday: jsonObject.TimeTable?.HoursWednesday,
            wednesdayAm: jsonObject.TimeTable?.WednesdayAm,
            wednesdayPm: jsonObject.TimeTable?.WednesdayPm,
            hoursThursday: jsonObject.TimeTable?.HoursThursday,
            thursdayAm: jsonObject.TimeTable?.ThursdayAm,
            thursdayPm: jsonObject.TimeTable?.ThursdayPm,
            hoursFriday: jsonObject.TimeTable?.HoursFriday,
            fridayAm: jsonObject.TimeTable?.FridayAm,
            fridayPm: jsonObject.TimeTable?.FridayPm,
            hoursSaturday: jsonObject.TimeTable?.HoursSaturday,
            saturdayAm: jsonObject.TimeTable?.SaturdayAm,
            saturdayPm: jsonObject.TimeTable?.SaturdayPm,
            hoursSunday: jsonObject.TimeTable?.HoursSunday,
            sundayAm: jsonObject.TimeTable?.SundayAm,
            sundayPm: jsonObject.TimeTable?.SundayPm,
        }
        
    }
}
