import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import City from "../../Models/City";
import Country from "../../Models/Country";
import Employee, { } from "../../Models/Employee";
import Nationality from "../../Models/Nationality";
import EmployeeService from "../../Services/EmployeeService";
import "../GlobalStyles.css";
import UserGeneralInfoForm from "./UserGeneralInfoForm";
import UserHeaderComponent from "../UserHeaderComponent";
import { toast } from "react-toastify";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { useMsal } from "@azure/msal-react";
import GraphService from '../../Services/GraphService';

export default function UserGeneralInfoPage(props: any) {
    const { instance } = useMsal();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const history = useHistory();
    const [employee, setEmployee] = useState<Employee>();
    const [cities, setCities] = useState<City[]>();
    const [birthPlaceCities, setBirthPlaceCities] = useState<City[]>();
    const [countries, setCountries] = useState<Country[]>();
    const [nationalities, setNationalities] = useState<Nationality[]>();

    useEffect(() => {
        getEmployee();
    }, []);

    async function getEmployee() {
        let user = await graphService.getUserDetails();
        let userName = user?.userPrincipalName as string;
        if (userName === null) {
            toast.error(`Kan geen gebruiker vinden.`);
            goBackToOverViewApp();
        }

        employeeService.getEmployeeByUserName().then((data: Employee) => {
            if (!data) {
                toast.error(`Kan geen gebruiker met de gebruikersnaam ${userName} vinden.`);
                goBackToOverViewApp();
            } else {
                setEmployee(data);
            }
        });
        employeeService.getAllCities().then((data) => {
            data.unshift({ id: "", name: "", value: "", postalCode: "" })
            setBirthPlaceCities(data)
            setCities(data)
        })
        employeeService.getAllCountries().then((data) => {
            data.unshift({ id: "", name: "", value: "" });
            setCountries(data);
        })
        employeeService.getAllNationalities().then((data) => {
            data.unshift({ id: "", name: "", value: "" });
            setNationalities(data);
        })
    }

    function goBackToOverViewApp(): void {
        history.push("/overview");
    }

    function getLoadSpinner() {
        return (
            <MoonLoader
                color="black"
                loading={!cities || !birthPlaceCities || !nationalities}
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    margin-top: 125px`
                }
                size={25} />
        );
    }

    function getPersonalDataDiv() {
        return (
            <div className="p-0 m-0">
                {employee && cities && birthPlaceCities && countries && nationalities &&
                    <UserGeneralInfoForm employee={employee} cities={cities} birthPlaceCities={birthPlaceCities} countries={countries} nationalities={nationalities} props={props} />
                }
            </div>
        );
    }

    return (
        <>
            {getLoadSpinner()}
            <UserHeaderComponent headerText={"Algemene info"} />

            {cities && birthPlaceCities && countries && nationalities &&
                <>
                    {getPersonalDataDiv()}
                </>
            }
        </>
    );
}