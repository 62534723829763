import Child from "./Child";
import EmployeeFunction from "./EmployeeFunction";

export default interface EmployeeFamily {
    // Algemeen
    contactId: string;
    ownerId: string;
    familyStatus: FamilyStatusType;
    maritalStateDate: Date;
    function: EmployeeFunction;

    //Partner
    partnerName: string;
    partnerFirstName: string;
    partnerBirthDate: Date;
    partnerIncome: boolean;
    partnerStatute: PartnerStatuteType;
    
    // Personen fiscaal
    amountOfChildren: number;
    amountOfChildrenDisabled: number;
    amountOfSeniors: number;
    amountOfSeniorsDisabled: number;

    // Kinderen
    children: Child[];

    hasNotApprovedChanges: Boolean;

}

export enum FamilyStatusType {
    Ongehuwd = 3,
    Gehuwd = 1,
    WeduweOfWeduwenaar = 2,
    WettelijkGescheiden = 4,
    FeitelijkOfVanTafelEnBedGescheiden = 5,
    Samenwonend = 6,
    WettelijkSamenwonend = 8
}  

export enum PartnerStatuteType
{
    Arbeider = 220950000,
    Dienstbode = 220950001,
    Bediende = 220950002,
    Zelfstandige = 220950003,
    VasteBeamteOfStagiair = 220950004,
    Andere = 220950005,
}  