import { Configuration, RedirectRequest } from "@azure/msal-browser";

export const config = {
    appId: 'f257cd49-20a5-40c0-86ab-58203d03fc3b',
    redirectUri: '' + process.env.REACT_APP_REDIRECTURI,
    authority: 'https://login.microsoftonline.com/5bf36a2f-7df2-47bc-9de4-92782f095579',
    scopes: [
        'User.Read',
        '' + process.env.REACT_APP_API_SCOPE
    ],
    graphScopes: [
        'User.Read',
    ],
    apiScopes: [
        '' + process.env.REACT_APP_API_SCOPE
    ]
};

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "f257cd49-20a5-40c0-86ab-58203d03fc3b",
        authority: 'https://login.microsoftonline.com/5bf36a2f-7df2-47bc-9de4-92782f095579',
        redirectUri: '' + process.env.REACT_APP_REDIRECTURI,
        postLogoutRedirectUri: '' + process.env.REACT_APP_REDIRECTURI
    }
};

export const loginRequest: RedirectRequest = {
    scopes: ["User.Read",
        '' + process.env.REACT_APP_API_SCOPE]
};

export const graphLoginRequest: RedirectRequest = {
    scopes: ["User.Read"]
};

export const apiLoginRequest: RedirectRequest = {
    scopes: ['' + process.env.REACT_APP_API_SCOPE]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};