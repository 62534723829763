import { Button, Col, Row, Form, Label, ButtonGroup } from 'reactstrap';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import '../ReactSearchSelect.css';
import "../GlobalStyles.css";
import { FuelType } from '../../Models/CompanyCar';
import Driver from '../../Models/Driver';
import { useState } from 'react';
import DriverService from '../../Services/DriverService';
import MileAgePopup from './MileAgePopup';
import useWindowHeight from '../../Hooks/useWindowHeight';
 
interface Props {
    driver: Driver;
    props: any;
    driverService: DriverService;
    getCompanyCar: Function;
    setIsLoading: Function;
}

const UserCompanyCarForm = (props: Props) => {
    const { windowHeight } = useWindowHeight();
    const history = useHistory();
    const { register } = useForm({
        defaultValues: {
            driver: props.driver
        }
    });
    const [isMileAgePopupOpen, setIsMileAgePopupOpen] = useState<boolean>(false);

    function getDateInputFormat(newDate: Date): string {
        const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');
        const date: Date = new Date(newDate);
        const dateStringFormat: string = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
        return dateStringFormat;
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{backgroundColor: "#2F5FA1"}} onClick={() => history.push("/overview-user")}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>     
                    <Button className="p-3 buttons-footer" style={{backgroundColor: "#2F5FA1"}} onClick={() => history.push("/overview")}>
                        <i className="fas fa-home fa-2x"></i>
                    </Button>                            
                </ButtonGroup>
            </>
        );
    }

    function getInputDateFormat(date: Date): string {
        if (date !== null) {
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
            const newDate: Date = new Date(date);
            const dateStringFormat: string = `${newDate.getFullYear()}-${zeroPad(newDate.getMonth() + 1, 2)}-${zeroPad(newDate.getDate(), 2)}`;
            return dateStringFormat;
        }
        return "";
    }

    function openMileAgePopup() {
        setIsMileAgePopupOpen(true);
    }

    function getPersonalForm() {
        return (
            <div className="p-0 m-0 mt-3">
                <Form>
                    <h5 className="mb-3"><b>Algemeen</b></h5>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Nummerplaat:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("driver.companyCar.licensePlate")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Chassisnummer:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("driver.companyCar.chassisNumber")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Merk wagen:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("driver.companyCar.brand.name")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Type wagen:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("driver.companyCar.type")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Datum eerste inschrijving:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="date" value={getDateInputFormat(props.driver.companyCar.firstRegistration)} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Brandstof:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" value={FuelType[props.driver.companyCar.fuelType]} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">CO2:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="number" {...register("driver.companyCar.co2")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <hr color="#007bff"></hr>
                    
                    <Row>
                        <Col xs={9}>
                            <h5 className="mb-3"><b>Laatste kilometerstand</b></h5>
                        </Col>
                        <Col xs={3} style={{textAlign: "end"}}>
                            {props.driver?.companyCar?.companyCarId && 
                                <Button className="mr-2" style={{backgroundColor: "#2F5FA1"}} onClick={() => openMileAgePopup()}>
                                    <i className="fas fa-plus fa-1x"></i>
                                </Button>    
                            }
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Datum:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="date" value={getInputDateFormat(props.driver.companyCar.mileAge?.date)} 
                                className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Kilometerstand:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="number" value={props.driver?.companyCar?.mileAge?.mileAgeNumber} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <hr color="#007bff"></hr>

                    <h5 className="mb-3"><b>Verzekering</b></h5>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Polisnummer:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("driver.companyCar.insuranceNumber")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-2 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Vlootnummer:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("driver.companyCar.fleetNumber")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                    <Row className="pl-2 pr-2 mb-3 d-flex align-items-center">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Franchise:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="number" {...register("driver.companyCar.franchise")} className="inputs-fixed-height form-control" readOnly/>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
    
    return (
        <>
            {getButtonDiv()}

            <MileAgePopup 
                driver={props.driver} 
                driverService={props.driverService} 
                isMileAgePopupOpen={isMileAgePopupOpen} 
                setIsMileAgePopupOpen={setIsMileAgePopupOpen}
                getCompanyCar={props.getCompanyCar}
                setIsPageLoading={props.setIsLoading}
            />

            <div style={{height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}>
                {getPersonalForm()}
            </div>
        </>
    );
};

export default UserCompanyCarForm;