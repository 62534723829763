import Child from "../Models/Child";
import City from "../Models/City";
import Country from "../Models/Country";
import Employee from "../Models/Employee";
import EmployeeFamily from "../Models/EmployeeFamily";
import EmployeeResponse from "../Models/Responses/EmployeeResponse";
import Nationality from "../Models/Nationality";
import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";

export default class EmployeeService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getEmployeeByUserName(): Promise<Employee> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetEmployeeByUserName`;
        let employee: Employee = null as any;
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'OnPremisesUserPrincipalName': sessionStorage.getItem('onPremisesUserPrincipalName') ?? ""
            })
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    employee = mapJsonObjectToEmployee(data);
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return employee;
    }

    public async updateEmployee(employee: Employee) {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/UpdateEmployee`;
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'PUT',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'OnPremisesUserPrincipalName': sessionStorage.getItem('onPremisesUserPrincipalName') ?? ""
            }),
            body: JSON.stringify(parseEmployeeToEmployeeResponse(employee))
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }
        }).catch((error: Error) => {
            console.log(error.message)
        });
    }

    public async getEmployeeFamilyByUserName(userName: string): Promise<EmployeeFamily> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetEmployeeFamilyByUserName`;
        let employeeFamily: EmployeeFamily = null as any;
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'OnPremisesUserPrincipalName': sessionStorage.getItem('onPremisesUserPrincipalName') ?? ""
            }),
            body: JSON.stringify(userName)
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    employeeFamily = mapJsonObjectToEmployeeFamily(data);
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return employeeFamily;
    }

    public async updateEmployeeFamily(employeeFamily: EmployeeFamily) {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/UpdateEmployeeFamily`;
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'PUT',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'OnPremisesUserPrincipalName': sessionStorage.getItem('onPremisesUserPrincipalName') ?? ""
            }),
            body: JSON.stringify(employeeFamily)
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }
        }).catch((error: Error) => {
            console.log(error.message)
        });
    }

    public async getChildrenByEmployeeContactId(contactId: string): Promise<Child[]> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetChildrenByEmployeeContactId`;
        let children: Child[] = [];
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,

            }),
            body: JSON.stringify(contactId)
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    data.forEach((responseObject: any) => {
                        let child: Child = mapJsonObjectToChild(responseObject)
                        children.push(child);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return children;
    }

    public async getAllCities(): Promise<City[]> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetAllCities`;
        let cities: City[] = [];
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,

            }),
        }).then(async function (response) {
            await response.json().then(data => {
                if (response.status === 500) {
                    throw new Error();
                }

                if (data) {
                    data.forEach((responseObject: any) => {
                        let city: City = mapJsonObjectToCity(responseObject)
                        cities.push(city);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return cities;
    }

    public async getAllCountries(): Promise<Country[]> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetAllCountries`;
        let countries: Country[] = [];
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,

            }),
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    data.forEach((responseObject: any) => {
                        let country: Country = mapJsonObjectToCountry(responseObject)
                        countries.push(country);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return countries;
    }

    public async getAllNationalities(): Promise<Nationality[]> {
        let baseUrl = process.env.REACT_APP_API_URI;
        let url = `${baseUrl}/api/GetAllNationalities`;
        let nationalities: Nationality[] = [];
        const accessToken = await this.acquireAccessToken();

        await fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,

            }),
        }).then(async function (response) {
            if (response.status === 500) {
                throw new Error();
            }

            await response.json().then(data => {
                if (data) {
                    data.forEach((responseObject: any) => {
                        let nationality: Nationality = mapJsonObjectToNationality(responseObject);
                        nationalities.push(nationality);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return nationalities;
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}

function mapJsonObjectToCity(jsonObject: any): City {
    return {
        id: jsonObject.Id,
        name: jsonObject.Name,
        value: jsonObject.Name,
        postalCode: jsonObject.PostalCode
    }
}

function mapJsonObjectToCountry(jsonObject: any): Country {
    return {
        id: jsonObject.ConfigurationId,
        name: jsonObject.Name,
        value: jsonObject.Name,
    }
}

function mapJsonObjectToNationality(jsonObject: any): Country {
    return {
        id: jsonObject.Id,
        name: jsonObject.Name,
        value: jsonObject.Name,
    }
}

function mapJsonObjectToEmployee(jsonObject: any): Employee {
    return {
        contactId: jsonObject.ContactId,
        ownerId: jsonObject.OwnerId,
        initialStartDate: jsonObject.InitialStartDate,
        statute: jsonObject.Statute,
        employeeNumber: jsonObject.EmployeeNumber,
        userName: jsonObject.UserName,
        internalEmailAddress: jsonObject.InternalEmailAddress,
        fullName: jsonObject.FullName,
        firstName: jsonObject.FirstName,
        lastName: jsonObject.LastName,
        age: jsonObject.Age,
        dateOfBirth: new Date(jsonObject.DateOfBirth),
        street: jsonObject.Street,
        houseNumber: jsonObject.HouseNumber,
        postBox: jsonObject.PostBox,
        language: jsonObject.Language,
        gender: jsonObject.Gender,
        bankAccount: jsonObject.BankAccount,

        fixedTelephone: jsonObject.FixedTelephone,
        mobilePhone: jsonObject.MobilePhone,
        privateEmailAddress: jsonObject.PrivateEmailAddress,

        travelType: jsonObject.TravelType,
        kmWorkHome: jsonObject.KmWorkHome,

        emergencyContact1: jsonObject.EmergencyContact1,
        emergencyContactLink1: jsonObject.EmergencyContactLink1,
        emergencyContactNumber1: jsonObject.EmergencyContactNumber1,
        emergencyContact2: jsonObject.EmergencyContact2,
        emergencyContactLink2: jsonObject.EmergencyContactLink2,
        emergencyContactNumber2: jsonObject.EmergencyContactNumber2,
        emergencyContact3: jsonObject.EmergencyContact3,
        emergencyContactLink3: jsonObject.EmergencyContactLink3,
        emergencyContactNumber3: jsonObject.EmergencyContactNumber3,

        hasNotApprovedChanges: jsonObject.HasNotApprovedChanges,

        city: {
            id: jsonObject.City?.Id,
            name: jsonObject.City?.Name,
            value: jsonObject.City?.Name,
            postalCode: jsonObject.City?.PostalCode
        },
        birthPlace: {
            id: jsonObject.BirthPlace?.Id,
            name: jsonObject.BirthPlace?.Name,
            value: jsonObject.BirthPlace?.Name,
            postalCode: jsonObject.BirthPlace?.PostalCode
        },
        nationality: {
            id: jsonObject.Nationality?.Id,
            name: jsonObject.Nationality?.Name,
            value: jsonObject.Nationality?.Name
        },
        country: {
            id: jsonObject.Country?.ConfigurationId,
            name: jsonObject.Country?.Name,
            value: jsonObject.Country?.Name
        },
        function: {
            id: jsonObject.Function?.Id ?? 0,
            name: jsonObject.Function?.Name ?? "/"
        },
        division: {
            businessUnitId: jsonObject.Division?.Id,
            name: jsonObject.Division?.Name,
        },
        department: {
            businessUnitId: jsonObject.Department?.Id,
            name: jsonObject.Department?.Name
        },
        partnership: {
            businessUnitId: jsonObject.Partnership?.Id,
            name: jsonObject.Partnership?.Name,
            prismaCode: jsonObject.Partnership?.PrismaCode
        }
    };
}

function mapJsonObjectToEmployeeFamily(jsonObject: any): EmployeeFamily {
    return {
        contactId: jsonObject.ContactId,
        ownerId: jsonObject.OwnerId,
        familyStatus: jsonObject.FamilyStatus,
        maritalStateDate: jsonObject.MaritalStateDate,
        partnerName: jsonObject.PartnerName,
        partnerFirstName: jsonObject.PartnerFirstName,
        partnerBirthDate: jsonObject.PartnerBirthDate,
        partnerIncome: jsonObject.PartnerIncome,
        partnerStatute: jsonObject.PartnerStatute,
        amountOfChildren: jsonObject.AmountOfChildren,
        amountOfChildrenDisabled: jsonObject.AmountOfChildrenDisabled,
        amountOfSeniors: jsonObject.AmountOfSeniors,
        amountOfSeniorsDisabled: jsonObject.AmountOfSeniorsDisabled,
        hasNotApprovedChanges: jsonObject.HasNotApprovedChanges,
        children: jsonObject.Children?.map(((data: any) => mapJsonObjectToChild(data))),
        function: {
            id: jsonObject.Function?.Id ?? 0,
            name: jsonObject.Function?.Name ?? "/"
        },
    }
}

function mapJsonObjectToChild(jsonObject: any): Child {
    return {
        childId: jsonObject.ChildId,
        contactId: jsonObject.ContactId,
        firstName: jsonObject.FirstName,
        lastName: jsonObject.LastName,
        birthDate: jsonObject.BirthDate,
    }
}

function parseEmployeeToEmployeeResponse(employee: Employee): EmployeeResponse {
    return {
        contactId: employee.contactId,
        firstName: employee.firstName,
        lastName: employee.lastName,
        dateOfBirth: employee.dateOfBirth,
        street: employee.street,
        houseNumber: employee.houseNumber,
        postBox: employee.postBox,
        language: employee.language,
        gender: employee.gender,
        bankAccount: employee.bankAccount,
        cityId: employee.city?.id,
        cityName: employee.city?.name,
        birthPlaceId: employee.birthPlace?.id,
        birthPlaceName: employee.birthPlace?.name,
        nationalityId: employee.nationality?.id,
        nationalityName: employee.nationality?.name,
        countryId: employee.country?.id,
        countryName: employee.country?.name,

        fixedTelephone: employee.fixedTelephone,
        mobilePhone: employee.mobilePhone,
        privateEmailAddress: employee.privateEmailAddress,

        travelType: employee.travelType,
        kmWorkHome: employee.kmWorkHome,

        emergencyContact1: employee.emergencyContact1,
        emergencyContactLink1: employee.emergencyContactLink1,
        emergencyContactNumber1: employee.emergencyContactNumber1,
        emergencyContact2: employee.emergencyContact2,
        emergencyContactLink2: employee.emergencyContactLink2,
        emergencyContactNumber2: employee.emergencyContactNumber2,
        emergencyContact3: employee.emergencyContact3,
        emergencyContactLink3: employee.emergencyContactLink3,
        emergencyContactNumber3: employee.emergencyContactNumber3
    }
}
