import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import EmployeeService from "../../Services/EmployeeService";
import "../GlobalStyles.css";
import UserFamilyForm from "./UserFamilyForm";
import EmployeeFamily from "../../Models/EmployeeFamily";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import UserHeaderComponent from "../UserHeaderComponent";
import { useMsal } from '@azure/msal-react';
import GraphService from '../../Services/GraphService';
import { User } from '@microsoft/microsoft-graph-types';

export default function UserFamilyPage(props: any) {
    const { instance } = useMsal();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const history = useHistory();
    const [employeeFamily, setEmployeeFamily] = useState<EmployeeFamily>();

    useEffect(() => {
        getEmployee();
    }, [])

    async function getEmployee() {
        let user = await graphService.getUserDetails();
        let userName = user?.userPrincipalName as string;
        if (userName === null) {
            toast.error(`Kan geen gebruiker vinden.`);
            goBackToOverViewApp();
        }

        employeeService.getEmployeeFamilyByUserName(userName).then((data: EmployeeFamily) => {
            if (!data) {
                toast.error(`Kan geen gebruiker met de gebruikersnaam ${userName} vinden.`);
                goBackToOverViewApp();
            } else {
                setEmployeeFamily(data);
            }
        });
    }

    function goBackToOverViewApp(): void {
        history.push("/overview");
    }

    function getLoadSpinner() {
        return (
            <MoonLoader
                color="black"
                loading={!employeeFamily}
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    top: 125px`
                }
                size={25} />
        );
    }

    function getPersonalDataDiv() {
        return (
            <div className="p-0 m-0">
                {employeeFamily ?
                    <UserFamilyForm employeeFamily={employeeFamily} props={props} />
                    : ""}
            </div>
        );
    }

    return (
        <>
            {getLoadSpinner()}

            <UserHeaderComponent headerText={"Gezin"} />
            {getPersonalDataDiv()}
        </>
    );
}