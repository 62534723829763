import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Button, ButtonGroup, Card, Col, Label, Row } from "reactstrap";
import EmployeeService from "../../Services/EmployeeService";
import "./UserAssetOverviewPage.css";
import "../GlobalStyles.css";
import EmployeeFamily from "../../Models/EmployeeFamily";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import AssetService from "../../Services/AssetService";
import AssetAllocation from "../../Models/AssetAllocation";
import { AssetType } from "../../Models/Asset";
import UserHeaderComponent from "../UserHeaderComponent";
import { useMsal } from "@azure/msal-react";
import GraphService from '../../Services/GraphService';
import { CardActionArea } from "@mui/material";
import useWindowHeight from "../../Hooks/useWindowHeight";

export default function UserAssetPage() {
    const { windowHeight } = useWindowHeight();
    const { instance } = useMsal();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const assetService = useMemo(() => new AssetService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const history = useHistory();
    const [assetAllocations, setAssetAllocations] = useState<AssetAllocation[]>();

    useEffect(() => {
        getAssets();
    }, []);

    async function getAssets() {
        let user = await graphService.getUserDetails();
        let userName = user?.userPrincipalName as string;
        if (userName === null) {
            goBackToOverViewApp();
            toast.error(`Kan geen gebruiker vinden.`);
        }

        employeeService.getEmployeeFamilyByUserName(userName).then((data: EmployeeFamily) => {
            if (!data) {
                toast.error(`Kan geen gebruiker met de gebruikersnaam ${userName} vinden.`);
                goBackToOverViewApp();
            } 
            return data;
        }).then((data: EmployeeFamily) => {
            assetService.getAssetsByEmployeeContactId(data.contactId).then((assets: AssetAllocation[]) => {
                setAssetAllocations(assets);
            });
        });
    }

    function getInputDateFormat(startDate: Date): string {
        if (startDate !== null) {
            const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
            const date: Date = startDate;
            const dateStringFormat: string = `${zeroPad(date.getDate(), 2)}-${zeroPad(date.getMonth() + 1, 2)}-${date.getFullYear()}`;
            return dateStringFormat;
        }
        return "";
    }

    function getStatuteTypeString(assetType: AssetType): string {
        switch (assetType) {
            case AssetType.MobieleTelefoon:
                return "Mobiele telefoon";
            case AssetType.PCLaptop:
                return "PC/Laptop";
            default:
                return AssetType[assetType];
        }
    }

    function goBackToOverViewApp(): void {
        history.push("/overview");
    }

    function goToAssetDetailPage(assetAllocation: AssetAllocation): void {
        history.push({
            pathname: '/user-assets-detail',
            state: assetAllocation
        });
    }

    function noAssetsFoundDiv() {
        if (assetAllocations) {
            return (
                <div className="pl-3 pt-3">
                    <Label>Geen werkmiddelen gevonden</Label>
                </div>
            );
        }
        return "";
    }

    function getLoadSpinner() {
        return (
            <MoonLoader
                color="black"
                loading={!assetAllocations}
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    top: 125px`
                }
                size={25} />
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/overview-user")}>
                        <i className="fas fa-arrow-left fa-2x"></i>
                    </Button>
                    <Button className="p-3 buttons-footer" style={{ backgroundColor: "#2F5FA1" }} onClick={() => history.push("/overview")}>
                        <i className="fas fa-home fa-2x"></i>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    function getAssetsOverview() {
        return (
            <div className="p-0 m-0">
                {assetAllocations && assetAllocations.length > 0 ?
                    <div style={{ height: `calc(${windowHeight}px - 185px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}>
                        {assetAllocations.map((assetAllocation: AssetAllocation, index: number) => {
                            return (
                                <Card key={index} className="mt-3 mb-3 asset">
                                    <CardActionArea onClick={() => goToAssetDetailPage(assetAllocation)}>
                                        <Row className="pl-3 pr-2 pt-2 mb-1 d-flex align-items-center">
                                            <Col xs="6">
                                                <Label className="m-1"><b>Type:</b></Label>
                                            </Col>
                                            <Col xs="6">
                                                <Label className="m-1">{getStatuteTypeString(assetAllocation.asset.type)}</Label>
                                            </Col>
                                        </Row>
                                        <Row className="pl-3 pr-2 mb-1 d-flex align-items-center">
                                            <Col xs="6">
                                                <Label className="m-1"><b>Naam:</b></Label>
                                            </Col>
                                            <Col xs="6">
                                                <Label className="m-1">{assetAllocation.asset.name}</Label>
                                            </Col>
                                        </Row>
                                        <Row className="pl-3 pr-2 d-flex align-items-center">
                                            <Col xs="6">
                                                <Label className="m-0"><b>Startdatum:</b></Label>
                                            </Col>
                                            <Col xs="6">
                                                <Label className="m-1">{getInputDateFormat(new Date(assetAllocation.startDate))}</Label>
                                            </Col>
                                        </Row>
                                        <Button className="detail-button pt-1"><b>Meer details</b></Button>
                                    </CardActionArea>
                                </Card>
                            );
                        })}
                    </div>
                    : noAssetsFoundDiv()}
            </div>
        );
    }

    return (
        <>
            {getLoadSpinner()}
            <UserHeaderComponent headerText={"Gegevens werkmiddelen"} />

            {assetAllocations &&
                <>
                    {getAssetsOverview()}
                    {getButtonDiv()}
                </>
            }
        </>
    );
}